import { CdnImage } from '@/components/images/CdnImage';
import { ICollection } from '@/interface/collection.interfaces';

interface CollectionCardProps {
	collection: ICollection;
}

export const CollectionCard = ({ collection }: CollectionCardProps) => {
	const getMediaContent = (index: number) => {
		if (collection.recipes && collection.recipes[index]) {
			const recipe = collection.recipes[index];
			const mediaUrl =
				recipe.instagramInformation?.thumbnail_url ??
				recipe.instagramInformation?.media_url;

			if (mediaUrl) {
				return (
					<CdnImage
						src={mediaUrl}
						alt={`${collection.title}`}
						className="w-full h-full object-cover rounded-lg"
					/>
				);
			}
		}
		return <div className="w-full h-full bg-slate-200 rounded-lg"></div>;
	};

	return (
		<div className="collection-card w-[200px] 2xl:w-[235px] flex flex-col cursor-pointer">
			<div className="grid grid-cols-3 grid-rows-2 h-[240px] rounded-xl overflow-hidden gap-1 bg-white p-1">
				<div className="col-span-2 row-span-2">{getMediaContent(0)}</div>
				<div className="col-span-1 row-span-1">{getMediaContent(1)}</div>
				<div className="col-span-1 row-span-1">{getMediaContent(2)}</div>
			</div>
			<div className="mt-2 flex flex-col items-start ml-1">
				<h2 className="w-full text-xl truncate font-semibold">
					{collection.title}
				</h2>
				<p className="w-full truncate text-sm text-gray-500">
					{collection.recipes?.length} Recipes
				</p>
			</div>
		</div>
	);
};
