import { decorativeImages } from '@/pages/signup-landing/decorative-images/decorativeImages';

export const ModalImages = () => {
	return (
		<section className="w-[480px] h-[638px] mt-4 rounded-[32px] justify-center items-center hidden md:flex bg-brandOrange">
			{decorativeImages.map((image, index) => (
				<img
					key={index}
					src={image.src}
					alt={image.alt}
					className={`absolute w-[322px] h-[412px] rounded-[32px] z-${image.zIndex} rotate-[${image.rotation}]`}
				/>
			))}
		</section>
	);
};
