import { useState } from 'react';

import cookingIcon from '@/assets/svg/cooking-hat.svg';
import { PopUp } from '@/components/common/PopUp';
import { ActionButtons } from '@/components/common/buttons/ActionButtons';
import usePutUserByEmail from '@/hooks/early-users/useAskToBecomeChefEarlyUsers';
import { EarlyAccessUserDto } from '@/interface/early-access-user.interfaces';

interface SuccessMessageProps {
	earlyUser?: EarlyAccessUserDto;
}

export const SuccessMessage = ({ earlyUser }: SuccessMessageProps) => {
	const [buttonVisible, setButtonVisible] = useState(true);
	const [open, setOpen] = useState(false);

	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);

	const { earlyUsersMutation } = usePutUserByEmail();

	const handleUserRequest = () => {
		if (earlyUser) {
			earlyUsersMutation.mutate(
				{ email: earlyUser.email },
				{
					onSuccess: () => {
						setButtonVisible(false);
						onCloseModal();
					},
				},
			);
		} else throw new Error('User not found please register first');
	};

	return (
		<div className="text-center font-helveticaNeue -tracking-wide mt-14">
			<p
				data-cy="comingsoon-successmessage"
				className="text-[22px] text-brandRed font-semibold mb-2"
			>
				Thank you for pre-registering!
			</p>
			<p className="text-[19px]">
				We'll notify you via email once Kitched is available.
			</p>
			<div className="w-full lg:max-w-[400px] mt-8 flex flex-col items-center gap-4 md:flex-row md:justify-center">
				{buttonVisible ? (
					<div>
						<button
							data-cy="become-creator-button"
							className="flex items-center justify-center w-full md:w-[200px] h-[52px] rounded-full bg-brandOrange shadow-md hover:shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105"
							onClick={onOpenModal}
						>
							<p className="text-[11px] text-white">
								I want to become a creator
							</p>
							<img
								src={cookingIcon}
								alt="arrow-icon"
								className="w-4 h-4 ml-2 -mt-[2px]"
							/>
						</button>
						<PopUp open={open} onClose={onCloseModal}>
							<div className="flex flex-col gap-y-2 p-6">
								<p className="text-lg text-center text-slate-600 font-semibold my-4">
									Are you sure you want to become a creator?
								</p>
								<ActionButtons
									buttonOneText="Yes"
									buttonTwoText="No"
									onButtonClickTwo={onCloseModal}
									onButtonClickOne={() => handleUserRequest()}
								/>
							</div>
						</PopUp>
					</div>
				) : (
					<p className="text-[11px]">
						You have correctly applied to become a chef. We will submit your
						application to our administrators
					</p>
				)}
			</div>
		</div>
	);
};
