import DropdownMenu from '@/components/common/DropdownMenu';
import { Loader } from '@/components/common/Loader';
import { Title } from '@/components/common/Title/Title';
import { User } from '@/context/interfaces';
import { useUserPreferences } from '@/hooks/user/useUserPreferences ';
import {
	FavoriteChefs,
	FavoriteIngredients,
	ICookingLevel,
} from '@/interface/user.interfaces';

interface CustomChoicesMenusProps {
	user: User;
}

export const CustomChoicesMenus = ({ user }: CustomChoicesMenusProps) => {
	const {
		preferences,
		handleChefChange,
		handleIngredientChange,
		handleCookingLevelChange,
		isPendingPreferencesChange,
	} = useUserPreferences(user);

	return (
		<div className="flex flex-col items-center">
			<span className="text-center text-slate-400">Custom Choices</span>
			<Title textTitle={`Preferences`} />

			<div className="space-y-4 pt-5 pb-10">
				{isPendingPreferencesChange ? (
					<Loader size={4} />
				) : (
					<>
						<DropdownMenu
							options={Object.values(ICookingLevel)}
							selectedValues={
								preferences.cookingLevel ? [preferences.cookingLevel] : []
							}
							label="COOKING EXPERIENCE"
							onChange={(level) =>
								handleCookingLevelChange(level as ICookingLevel)
							}
							renderItem={(level) => (
								<span className="block px-2 py-2 text-sm">{level}</span>
							)}
						/>

						<DropdownMenu
							options={Object.values(FavoriteChefs)}
							selectedValues={preferences.favoriteChefs || []}
							label="FAVOURITES CREATORS"
							onChange={(chef) => handleChefChange(chef)}
							renderItem={(chef) => (
								<span className="block px-2 py-2 text-sm">{chef}</span>
							)}
						/>

						<DropdownMenu
							options={Object.values(FavoriteIngredients)}
							selectedValues={preferences.favoriteIngredients || []}
							label="FAVOURITES INGREDIENTS"
							onChange={(ingredient) => handleIngredientChange(ingredient)}
							renderItem={(ingredient) => (
								<span className="block px-2 py-2 text-sm">{ingredient}</span>
							)}
						/>
					</>
				)}
			</div>
		</div>
	);
};
