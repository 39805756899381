import { useEffect, useState } from 'react';

import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { LatestRecipesHomeSection } from '@/components/home/LatestRecipesHomeSection';
import { RecipesForYouByPreferences } from '@/components/home/RecipesForYouByPreference';
import { RecipesForYou } from '@/components/home/RecipesForYouSection';
import { TrendingChefsSection } from '@/components/home/TrendingChefsSection';
import { TrendingOnKitchedSection } from '@/components/home/TrendingOnKitchedSection';
import { useAuth } from '@/context/useAuth';
import { useSetInstagramToken } from '@/hooks/chef/useSetInstagramToken';

export default function Home() {
	const { user } = useAuth();
	const [open, setOpen] = useState(false);
	const onCloseModal = () => setOpen(false);

	const urlParams = new URLSearchParams(window.location.search);
	const code = urlParams.get('code');

	const chefId = user?.chef && user?.chef.id;

	const { isSuccess, isError, error, isLoading } = useSetInstagramToken(
		chefId,
		code,
	);

	useEffect(() => {
		if (isLoading || isError) {
			setOpen(true);
		} else {
			setOpen(false);
		}
	}, [isLoading, isError]);

	if (isSuccess) {
		window.location.replace('/chef/profile/instagram-posts');
	}

	const hasPreferences =
		user?.recipePreferences?.length ||
		user?.cookingLevel ||
		user?.favoriteChefs?.length ||
		user?.favoriteIngredients?.length;

	return (
		<div className="flex flex-col items-center justify-center gap-y-12">
			<TrendingOnKitchedSection />

			<TrendingChefsSection />

			{hasPreferences ? <RecipesForYouByPreferences /> : <RecipesForYou />}

			<LatestRecipesHomeSection />

			<PopUp open={open} onClose={onCloseModal} topCss="20vh">
				{!isError && (
					<div className="flex flex-col items-center justify-center w-80 h-60 ">
						<Loader size={1.5} />
						<p className=" text-center mt-2">
							Please wait a second to complete the process..
						</p>
					</div>
				)}
				{isError && (
					<div className="flex flex-col items-center justify-center w-80 h-60 ">
						<p className="text-xl text-center text-alertRed mb-4">
							Something went wrong setting your Instagram token:
						</p>

						<p className="text-center text-alertRed">{error?.message}</p>
					</div>
				)}
			</PopUp>
		</div>
	);
}
