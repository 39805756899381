export interface ImageInfo {
	src: string;
	alt: string;
	zIndex: number;
	rotation: string;
}

export const decorativeImages: ImageInfo[] = [
	{
		src: '/images/landing-page/recipe-1.png',
		alt: 'Recipe',
		zIndex: 40,
		rotation: '0deg',
	},
	{
		src: '/images/landing-page/recipe-2.png',
		alt: 'Recipe',
		zIndex: 30,
		rotation: '-8.2deg',
	},
	{
		src: '/images/landing-page/recipe-3.png',
		alt: 'Recipe',
		zIndex: 20,
		rotation: '-17.13deg',
	},
	{
		src: '/images/landing-page/recipe-4.png',
		alt: 'Recipe',
		zIndex: 10,
		rotation: '-28.25deg',
	},
];
