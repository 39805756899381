import { IRecipe } from './recipe.interfaces';

export enum RecipeMealType {
	BREAKFAST = 'Breakfast',
	LUNCH = 'Lunch',
	SNACK = 'Snack',
	DINNER = 'Dinner',
}

export interface MealPlan {
	id: number;
	uuid: string;
	recipes: IRecipe[];
	date: string;
	mealType: RecipeMealType;
}

export interface MealPlanDto {
	recipes: number[];
	date: Date;
	mealType: RecipeMealType;
}
