import { ICollection } from '@/interface/collection.interfaces';
import { IRecipe } from '@/interface/recipe.interfaces';
import {
	FavoriteChefs,
	FavoriteIngredients,
	ICookingLevel,
	RecipePreferences,
} from '@/interface/user.interfaces';

export interface ProfileImage {
	id: number;
	src: string;
	title?: string;
	description?: string;
}

export enum Role {
	ADMIN = 'admin',
	USER = 'user',
	CHEF = 'chef',
}

export interface User {
	id: number;
	externalId: string;
	name: string;
	last_name: string;
	email: string;
	chef?: Chef;
	favoritesRecipes: IRecipe[];
	savedRecipes: IRecipe[];
	profile_image: ProfileImage;
	following: Chef[];
	numberOfFollowings: number;
	createdAt: Date;
	updatedAt: Date;
	role: Role;
	recipePreferences?: RecipePreferences[];
	cookingLevel?: ICookingLevel;
	favoriteChefs?: FavoriteChefs[];
	favoriteIngredients?: FavoriteIngredients[];
	collections?: ICollection[];
}

export interface Chef {
	id: number;
	user: User;
	followers?: User[];
	socialAccount?: SocialAccount[];
	recipes?: IRecipe[];
	numberOfFollowers?: number;
	numberOfRecipes?: number;
	numberOfRecipesLikes?: number;
	bio?: string;
	uuid: string;
}

export interface SocialAccount {
	id: number;
	name: string;
	username: string;
}

export interface LoginData {
	email: string;
	password: string;
}

export interface AuthContextProps {
	user: User | null;
	setUser: React.Dispatch<React.SetStateAction<User | null>>;
	login: (loginData: LoginData) => Promise<void>;
	logout: () => void;
	loading: boolean;
	fetchUser: () => void;
}
