import { ModalImages } from '@/pages/signup-landing/decorative-images/ModalImages';
import { FormModal } from '@/pages/signup-landing/modal/form/FormModal';

export const SignUpModal = () => {
	return (
		<section className="min-w-auto max-w-[992px] h-[620px] mb-5 px-5 mx-auto flex justify-between rounded-3xl sm-custom:justify-center">
			<div className="gap-14 flex">
				<div className="-mt-4 -ml-5 hidden ml-custom:block">
					<ModalImages />
				</div>
				<div className="w-full lg:w-1/2 px-auto">
					<FormModal />
				</div>
			</div>
		</section>
	);
};
