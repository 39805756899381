import { Link } from 'react-router-dom';

import logo from '@/assets/svg/footer-logo.svg';
import homeIcon from '@/assets/svg/home-gray-icon.svg';
import instagramBlack from '@/assets/svg/instagram-black.svg';
import linkedinBlack from '@/assets/svg/linkedin-icon.svg';
import tiktokBlack from '@/assets/svg/tiktok-black.svg';

export const Footer = () => {
	const INSTAGRAM_PAGE = 'https://www.instagram.com/letsgetkitched/';
	const TIKTOK_PAGE = 'https://www.tiktok.com/@letsgetkitched';
	const LINKEDIN_PAGE = 'https://www.linkedin.com/company/kitched/';

	const handleExternalLink = (url: string) => {
		window.open(url, '_blank', 'noopener,noreferrer');
	};

	return (
		<footer
			data-cy="footer"
			className="w-11/12 bg-[#F8F8FA] p-6 rounded-2xl my-6 font-helveticaNeue"
		>
			<div className="flex flex-col-reverse gap-y-12 justify-between mb-16 lg:flex-row">
				<div className="flex flex-col-reverse gap-y-12 md:flex-row gap-x-12">
					<div className="md:w-2/5">
						<Link to="/" data-cy="navbar-logo">
							<img src={logo} alt="Flowbite Logo" className="h-6 mb-4" />
						</Link>
						<div className="flex flex-col font-bold text-xs text-slate-500">
							<span>YOUR FAVOURITE RECIPES</span>
							<span>YOUR FAVOURITE CREATORS</span>
							<span>ALL IN ONE PLACE</span>
						</div>
					</div>

					<div className="flex justify-around md:w-9/12 md:justify-normal md:gap-x-10">
						<div>
							<h4 className="text-xs text-friendlyRed mb-4">COMPANY</h4>
							<div className="flex flex-col gap-y-2 text-sm">
								<span>About us</span>
								<span>Careers</span>
								<span>Contact us</span>
							</div>
						</div>
						<div>
							<h4 className="text-xs text-friendlyRed mb-4">RESOURCES</h4>
							<div className="flex flex-col gap-y-2 text-sm">
								<span>Blog</span>
								<span>Help</span>
								<span>Terms of Conditions</span>
							</div>
						</div>
					</div>
				</div>

				<div className="md:w-2/3 lg:w-2/5 flex flex-col gap-y-4 mt-[-6px]">
					<h2 className="text-xl md:text-3xl font-semibold ">
						Subscribe for Updates
					</h2>
					<p className="text-slate-500 text-xs">
						Stay up to date with all the stuff we're creating - we'll share our
						thoughts behind everything we build
					</p>
					<div className="relative">
						<label
							htmlFor="email"
							className="text-slate-500 text-[0.6rem] absolute top-3 left-2.5 px-2"
						>
							E-MAIL
						</label>
						<input
							type="text"
							className="w-full border-2 border-slate-300 pt-6 pb-2 px-4 rounded-3xl"
							placeholder="Write your email"
						/>
						<img
							src={homeIcon}
							alt="home-icon"
							className="absolute top-4 right-3.5"
						/>
					</div>
				</div>
			</div>

			<div className="flex flex-col items-center gap-y-6 md:bg-white p-4 rounded-2xl md:flex-row md:justify-between">
				<div className="flex place-self-start md:place-self-center gap-x-6">
					<div onClick={() => handleExternalLink(INSTAGRAM_PAGE)}>
						<img
							src={instagramBlack}
							alt="instagram-icon"
							className="p-2 bg-white rounded-full md:rounded-none md:p-0 cursor-pointer"
						/>
					</div>
					<div onClick={() => handleExternalLink(TIKTOK_PAGE)}>
						<img
							src={tiktokBlack}
							alt="tiktok-icon"
							className="p-2 bg-white rounded-full md:rounded-none md:p-0 cursor-pointer"
						/>
					</div>
					<div onClick={() => handleExternalLink(LINKEDIN_PAGE)}>
						<img
							src={linkedinBlack}
							alt="linkedin-icon"
							className="p-2 bg-white rounded-full md:rounded-none md:p-0 cursor-pointer"
						/>
					</div>
				</div>
				<div className="flex gap-x-4 text-[0.6rem] text-slate-500">
					<span>PRIVACY POLICY</span>
					<span>COOKIE POLICY</span>
					<span className="hidden md:block">@COPYRIGHT KITCHED 2024</span>
				</div>
			</div>
		</footer>
	);
};
