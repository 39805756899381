import * as Yup from 'yup';

export const createMealPlanValidationSchema = () => {
	return Yup.object({
		mealType: Yup.string().required('Meal type is required'),
		recipesSelected: Yup.array()
			.of(Yup.number().required('Recipe ID is required'))
			.min(1, 'At least one recipe is required'),
		date: Yup.string().required('Date is required'),
	});
};
