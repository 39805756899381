import { Outlet, useLocation } from 'react-router-dom';

import { Footer } from '@/components/footer/Footer';
import { BackgroundVideoSection } from '@/components/home/BackgroundVideoSection';
import AnalyticsTracker from '@/service/analytics/AnalyticsTracker';

import Navbar from '@components/navbar/Navbar';

export default function Root() {
	const location = useLocation();
	const hideNavbarRoutes = ['/forgot-password', '/resend-verification'];

	return (
		<div className="flex flex-col items-center justify-center  relative">
			<header className="w-full ">
				{!hideNavbarRoutes.includes(location.pathname) && <Navbar />}
				{location.pathname == '/' && <BackgroundVideoSection />}
			</header>
			<main className="w-full min-h-screen">
				<AnalyticsTracker />
				<Outlet />
			</main>
			{location.pathname === '/early-user' ? <div></div> : <Footer />}
		</div>
	);
}
