import { createContext, useCallback, useEffect, useState } from 'react';

import { AuthContextProps, LoginData, User } from '@/context/interfaces';
import { getUser } from '@/service/api/user';
import {
	authenticateUser,
	logoutUser,
	refreshUserToken,
} from '@/service/cognito/cognito.services';

export const AuthContext = createContext<AuthContextProps | undefined>(
	undefined,
);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
	const [user, setUser] = useState<User | null>(null);
	const [loading, setLoading] = useState(true);

	const fetchUser = useCallback(async () => {
		try {
			setLoading(true);
			const { session } = await refreshUserToken();
			if (!session) return;
			const user = await getUser(session.getAccessToken().getJwtToken());
			setUser(user);
		} catch (error) {
			console.error('Error fetching user', error);
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		fetchUser();
	}, []);

	const login = async (loginData: LoginData): Promise<void> => {
		const user = await authenticateUser(loginData);
		if (user) setUser(user);
	};

	const logout = () => {
		logoutUser();
		setUser(null);
	};

	return (
		<AuthContext.Provider
			value={{ user, login, logout, setUser, loading, fetchUser }}
		>
			{children}
		</AuthContext.Provider>
	);
};
