import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { SocialMedia } from '../settings/profile-settings/ProfileUserSettings';
import { ChefAvatar } from './ChefAvatar';

import { chefRecipesFiltersMenu } from '@/assets/mock/date_filter_items.mock';
import facebookIcon from '@/assets/svg/facebook-green.svg';
import instagramIcon from '@/assets/svg/instagram-green.svg';
import twitterIcon from '@/assets/svg/twitter-green.svg';
import youtubeIcon from '@/assets/svg/youtube-green.svg';
import { Loader } from '@/components/common/Loader';
import { ReactPlayerComponent } from '@/components/common/ReactPlayerComponent';
import { FollowButton } from '@/components/common/buttons/FollowButton';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { TagsSearchMenuPopUp } from '@/components/common/pop-up/TagsPopUp';
import { RecipesNav } from '@/components/common/recipe/RecipesNav';
import { TagsSearchBar } from '@/components/common/recipe/TagsSearchBar';
import { RecipeDinamicCardSkeletond } from '@/components/common/recipe/masonry-recipe/RecipeDinamicCardSkeleton';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { TrendingOnKitchedSection } from '@/components/home/TrendingOnKitchedSection';
import { ScrollToTop } from '@/helper/scrollToTop';
import { upperCaseFirstLetter } from '@/helper/upperCaseFirstLetter';
import { useFetchChefById } from '@/hooks/chef/useFetchChefById';
import { useGetChefRecipes } from '@/hooks/recipes/useGetChefRecipes';
import { usePagination } from '@/hooks/usePagination';
import { RecipeSort } from '@/interface/filter.interface';
import { IInstagramInformation } from '@/interface/recipe.interfaces';
import { logChefProfileView } from '@/service/analytics/analytics';

enum SocialMediaLinks {
	FACEBOOK = 'https://facebook.com/',
	INSTAGRAM = 'https://instagram.com/',
	TWITTER = 'https://x.com/',
	YOUTUBE = 'https://youtube.com/',
}

function getSocialLinks(socialMediaName: string) {
	switch (socialMediaName) {
		case SocialMedia.FACEBOOK:
			return SocialMediaLinks.FACEBOOK;
		case SocialMedia.INSTAGRAM:
			return SocialMediaLinks.INSTAGRAM;
		case SocialMedia.TWITTER:
			return SocialMediaLinks.TWITTER;
		case SocialMedia.YOUTUBE:
			return SocialMediaLinks.YOUTUBE;
	}
}

export const ChefPage = () => {
	const { uuid } = useParams();
	const [open, setOpen] = useState(false);
	const onCloseModal = () => setOpen(false);
	const [tags, setTags] = useState<string>();
	const [sort, setSort] = useState(RecipeSort.LATEST);
	const [skip, setSkip] = useState(0);
	const take = 12;
	const { chef, errorChef, isErrorLoadingChef, isLoadingChef } =
		useFetchChefById();
	const {
		chefRecipes,
		isLoadingChefRecipes,
		refetchChefRecipes,
		totalEntities,
	} = useGetChefRecipes(uuid as string, { take, skip, sort, tags });
	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});
	const initialFollowers = chef?.numberOfFollowers ?? 0;
	(chef?.socialAccount && chef?.socialAccount.length > 0) || chef?.bio;
	const hasSocialAccountsAndBio =
		chef?.socialAccount && chef?.socialAccount.length > 0 && chef?.bio;
	const [numberOfFollowers, setNumberOfFollowers] =
		useState<number>(initialFollowers);

	const onHandleSearchTag = (queryTags: string) => {
		setTags(queryTags);
	};

	const handleFollowChange = (isFollowing: boolean) => {
		setNumberOfFollowers((prev) =>
			isFollowing ? (prev ? prev + 1 : 1) : prev ? prev - 1 : 0,
		);
	};

	useEffect(() => {
		if (chef) {
			setNumberOfFollowers(chef.numberOfFollowers ?? 0);
			const chefFullName = `${chef.user.name} ${chef.user.last_name}`;
			logChefProfileView(chef.uuid, chefFullName);
		}
	}, [chef]);

	useEffect(() => {
		refetchChefRecipes();
	}, [skip, sort, tags]);

	if (isLoadingChef)
		return (
			<div className="flex justify-center items-center mt-[25vh]">
				<Loader size={4} />
			</div>
		);

	if (isErrorLoadingChef) {
		return (
			<div
				className="flex mt-20 justify-center items-center  flex-col w-full   gap-6"
				data-cy="chef-page-error"
			>
				<p className="text-black text-3xl sm:text-4xl text-center font-sans font-bold tracking-tighter w-[50%]">
					{errorChef?.message}
				</p>
			</div>
		);
	}
	return (
		<div className="flex flex-col items-center gap-y-12">
			<ScrollToTop />
			<div className="w-full flex flex-col items-center gap-y-8 md:flex-row justify-evenly mt-16 mb-8">
				<ChefAvatar src={chef?.user?.profile_image?.src as string} />
				<div className="flex flex-col items-center justify-center">
					<div
						className={`flex flex-col items-center ${
							hasSocialAccountsAndBio
								? 'justify-around'
								: 'justify-center gap-4'
						} h-full`}
					>
						<div className="flex flex-col sm:flex-row gap-2 sm:gap-6 items-center">
							<h3
								className="text-3xl font-semibold sm:text-4xl 2xl:text-5xl"
								data-cy="title-chef-name"
							>
								{chef?.user.name} {chef?.user.last_name}
							</h3>
							<FollowButton chef={chef} onFollowChange={handleFollowChange} />
						</div>
						{chef?.bio && (
							<div
								className="text-center font-helveticaNeue font-normal text-[13px] leading-[18.2px] tracking-[0.01em] text-[#666666] text-wrap w-[370px] sm:w-[447px]"
								data-cy="chef-page-bio"
							>
								{chef?.bio}
							</div>
						)}
						<div
							className="flex text-xs min-[400px]:text-base sm:text-lg gap-x-3 font-semibold"
							data-cy="chef-stats"
						>
							<div className="flex gap-x-1">
								<span className="" data-cy="followers-length">
									{numberOfFollowers}
								</span>
								<span className="text-grayText" data-cy="followers-text">
									followers
								</span>
							</div>
							<span className="hidden min-[480px]:block mx-1 text-grayText">
								|
							</span>
							<div className="flex gap-x-1">
								<span className="" data-cy="recipes-length">
									{chef?.numberOfRecipes}
								</span>
								<span className="text-grayText" data-cy="recipes-text">
									recipes
								</span>
							</div>
							<span className="hidden min-[480px]:block mx-1 text-grayText">
								|
							</span>
							<div className="flex gap-x-1">
								<span className="" data-cy="masterclasses-length">
									0
								</span>
								<span className="text-grayText" data-cy="masterclasses-text">
									masterclasses
								</span>
							</div>
						</div>
						{chef?.socialAccount && chef?.socialAccount.length > 0 && (
							<div className="flex justify-center gap-4">
								{chef?.socialAccount &&
									chef.socialAccount.map((account) => {
										let socialMediaIcon = '';
										if (account.name === SocialMedia.FACEBOOK) {
											socialMediaIcon = facebookIcon;
										} else if (account.name === SocialMedia.TWITTER) {
											socialMediaIcon = twitterIcon;
										} else if (account.name === SocialMedia.INSTAGRAM) {
											socialMediaIcon = instagramIcon;
										} else if ((account.name = SocialMedia.YOUTUBE)) {
											socialMediaIcon = youtubeIcon;
										}
										return (
											<div
												key={account.id}
												className="flex items-center gap-x-1 "
												data-cy="social-media-container"
											>
												<img
													src={socialMediaIcon}
													alt={account.name}
													className="w-5 h-5"
													data-cy="social-media-icon"
												/>
												<a
													href={`${getSocialLinks(account.name)}${
														account.username
													}`}
													target="_blank"
												>
													<span className="text-sm hover:text-brandGreen">
														{upperCaseFirstLetter(account.name)}
													</span>
												</a>
											</div>
										);
									})}
							</div>
						)}
					</div>
				</div>
				<div className="hidden lg:block">
					{chefRecipes?.data[0]?.instagramInformation ? (
						<ReactPlayerComponent
							mediaInfo={
								chefRecipes?.data[0]
									.instagramInformation as IInstagramInformation
							}
						/>
					) : (
						<div className="flex justify-center items-center w-[200px] 2xl:w-[235px] h-[300px] rounded-3xl bg-slate-300">
							{!isLoadingChefRecipes ? (
								<ChefAvatar src="/images/recipe-pasta.png" />
							) : (
								<Loader size={2} />
							)}
						</div>
					)}
				</div>
			</div>

			{isLoadingChefRecipes ? (
				<div className="my-16">
					<Loader size={4} />
				</div>
			) : (
				<div className="w-full flex flex-col gap-y-6 mt-12 px-6">
					<RecipesNav
						textTitle="All"
						colorText="brandRed"
						titleWithColor={`${chef?.user.name}'s`}
						optionalText="Recipes"
						filtersMenu={chefRecipesFiltersMenu}
						setSort={setSort}
						setOpen={setOpen}
					/>

					<TagsSearchBar onHandleSearchTagByQuery={onHandleSearchTag} />

					{(chefRecipes?.total as number) === 0 ? (
						<RecipeDinamicCardSkeletond
							height="normal"
							message="No recipes found"
						/>
					) : (
						<div
							data-cy="chef-page-all-recipes"
							className="grid justify-center gap-y-10 gap-x-2 min-[375px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-[repeat(6,235px)]"
						>
							{chefRecipes?.data.map((recipe) => (
								<RecipesCard
									key={recipe.id}
									recipe={recipe}
									isChefPage
									resizeInFullScreen
								/>
							))}
						</div>
					)}

					<NavigationButtons
						currentPage={currentPage}
						totalPages={totalPages}
						handleNextClick={onHandleNext}
						handlePrevClick={onHandlePrevious}
					/>

					<TagsSearchMenuPopUp
						onHandleSearchTag={onHandleSearchTag}
						open={open}
						onCloseModal={onCloseModal}
					/>
				</div>
			)}

			<div className="w-full mb-12">
				<TrendingOnKitchedSection />
			</div>
		</div>
	);
};
