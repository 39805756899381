import { useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router-dom';

import playIcon from '@/assets/svg/play-icon.svg';
import { CdnImage } from '@/components/images/CdnImage';
import useWindowWidth from '@/hooks/useWindowWidth';

import soundIcon from '@assets/image/sound-icon.png';
import soundlessIcon from '@assets/image/soundless-icon.png';

interface renderVideoOrImageProps {
	isVideoFetched: boolean;
	mediaUrl: string | undefined;
	hasThumbnail: string | false | undefined;
	play: boolean;
	isVideo: boolean | undefined;
	handlePlayToggle: () => void;
	recipeUuid: string;
	containerStyle?: string;
	imageStyle?: string;
	recipeTitle: string;
	chefFullName: string;
	onPlayClick?: () => void;
	disableNavigation?: boolean;
}

export const RenderVideoOrImage = ({
	isVideoFetched,
	mediaUrl,
	hasThumbnail,
	play,
	isVideo,
	handlePlayToggle,
	recipeUuid,
	containerStyle,
	imageStyle,
	onPlayClick,
	disableNavigation = false,
}: renderVideoOrImageProps) => {
	const [videoMuted, setVideoMuted] = useState<boolean>(true);
	const navigate = useNavigate();
	const windowWidth = useWindowWidth();

	const handleContainerClick = () => {
		if (!disableNavigation) {
			if (windowWidth < 1024) {
				if (!isVideo) {
					navigate(`/recipe/detail/${recipeUuid}`);
				} else {
					handlePlayToggle();
					onPlayClick && onPlayClick();
				}
			} else {
				navigate(`/recipe/detail/${recipeUuid}`);
			}
		}
	};

	if (isVideoFetched) {
		return (
			<div
				onClick={handleContainerClick}
				className={`${containerStyle && containerStyle} relative`}
			>
				<div
					className={`${
						imageStyle
							? imageStyle
							: 'h-[200px] min-[500px]:h-[250px] sm:h-[300px]'
					}  w-full rounded-3xl overflow-hidden cursor-pointer`}
					style={{
						backgroundImage: `${
							hasThumbnail ? `url(${hasThumbnail}` : '/images/recipe-pasta.png'
						})`,
						backgroundSize: 'cover',
						backgroundPosition: 'center',
					}}
				>
					<ReactPlayer
						url={mediaUrl}
						playing={play}
						width="100%"
						height="100%"
						style={{
							objectFit: 'fill',
						}}
						muted={videoMuted}
						playsinline={true}
						className="video-player"
						controls={false}
						loop
					/>
				</div>
				{play ? (
					<button
						className="flex items-center justify-center absolute bottom-4 right-4 h-[30px] w-[30px] rounded-full shadow-black shadow-inner p-[2px] "
						id="videoButton"
						onClick={(e) => {
							e.stopPropagation();
							setVideoMuted(!videoMuted);
						}}
					>
						{
							<img
								src={videoMuted ? soundlessIcon : soundIcon}
								alt={videoMuted ? 'soundless' : 'sound'}
							/>
						}
					</button>
				) : (
					<button
						className="flex items-center justify-center absolute top-2 right-3 h-[30px] w-[30px]  rounded-full  p-[2px] "
						id="videoButton"
						data-cy="test-play-icon"
						onClick={(e) => {
							e.stopPropagation();
							handlePlayToggle();
							onPlayClick && onPlayClick();
						}}
					>
						<img src={playIcon} alt="play-icon" />
					</button>
				)}
			</div>
		);
	} else {
		return (
			<div
				onClick={handleContainerClick}
				className={`${containerStyle && containerStyle}`}
			>
				<CdnImage
					src={
						hasThumbnail
							? hasThumbnail
							: isVideo
							? '/images/recipe-pasta.png'
							: mediaUrl!
					}
					alt="recipe-image"
					className={`${
						imageStyle
							? imageStyle
							: 'h-[200px] min-[500px]:h-[250px] sm:h-[300px]'
					} w-full object-cover rounded-3xl cursor-pointer`}
				/>
				{isVideo && (
					<button
						className="flex items-center justify-center absolute top-2 right-3 h-[20px] w-[20px] rounded-full p-[2px]"
						id="videoButton"
						data-cy="test-play-icon"
						onClick={(e) => {
							e.stopPropagation();
							handlePlayToggle();
							onPlayClick && onPlayClick();
						}}
					>
						{<img src={playIcon} alt={'play-icon'} className="self-center " />}
					</button>
				)}
			</div>
		);
	}
};
