import { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';

import settingsIcon from '@/assets/svg/settings-black.svg';
import userBlackIcon from '@/assets/svg/user-black.svg';
import { Loader } from '@/components/common/Loader';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { CdnImage } from '@/components/images/CdnImage';
import { useAuth } from '@/context/useAuth';
import { useGetCollectionsByUser } from '@/hooks/collection/useGetCollectionsByUser';
import { usePagination } from '@/hooks/usePagination';
import { useGetUserLikedRecipes } from '@/hooks/user/useGetUserLikedRecipes';
import { useGetUserSavedRecipes } from '@/hooks/user/useGetUserSavedRecipes';
import { defaultPortfolioPaginationOptions } from '@/interface/pagination.interface';
import { TabProfileNav } from '@/pages/user/TabProfileNav';
import { CollectionCard } from '@/pages/user/collections/CollectionCard';

export const UserPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const [activeTab, setActiveTab] = useState('saved');

	const { user } = useAuth();
	const [skip, setSkip] = useState(0);
	const take = defaultPortfolioPaginationOptions.take;

	const { userLikedRecipes, totalUserLikedEntities } = useGetUserLikedRecipes({
		take,
		skip,
	});

	const { userSavedRecipes, refetchUserSavedRecipes, totalUserSavedEntities } =
		useGetUserSavedRecipes({ take, skip });

	const {
		collections,
		totalCollections,
		isLoadingCollections,
		isErrorCollections,
		errorCollections,
	} = useGetCollectionsByUser({ take, skip });

	const getTotalEntities = () => {
		switch (activeTab) {
			case 'saved':
				return totalUserSavedEntities;
			case 'favourites':
				return totalUserLikedEntities;
			case 'collections':
				return totalCollections;
			default:
				return 0;
		}
	};

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities: getTotalEntities(),
			setSkip,
		});

	const resetSkip = () => setSkip(0);

	useEffect(() => {
		resetSkip();
	}, [activeTab]);

	useEffect(() => {
		if (searchParams.has('tab')) {
			setActiveTab('saved');
			refetchUserSavedRecipes();
			searchParams.delete('tab');
			setSearchParams(searchParams);
			return;
		}
	}, [activeTab, skip, searchParams]);

	if (!user) {
		return (
			<div className="flex justify-center items-center h-[40vh]">
				<Loader size={2} />
			</div>
		);
	}

	if (isLoadingCollections && activeTab === 'collections') {
		return (
			<div className="flex justify-center items-center h-[40vh]">
				<Loader size={2} />
			</div>
		);
	}

	if (isErrorCollections && activeTab === 'collections') {
		return (
			<div className="flex justify-center items-center h-[40vh] text-alertRed">
				<p>Error: {errorCollections?.message}</p>
			</div>
		);
	}

	const savedRecipesCount = totalUserSavedEntities || 0;
	const favouritesRecipesCount = totalUserLikedEntities || 0;

	return (
		<div className="flex flex-col items-center mt-4 font-helveticaNeue">
			<div className="sm-custom:justify-center w-full sm-custom:w-[calc(100%-40px)] h-[224px] sm-custom:h-auto bg-buttonGrey rounded-bl-[24px] rounded-br-[24px] flex flex-col sm:flex-row lg:justify-between lg:items-start px-5">
				<div className="w-[218px] h-[186px] gap-2 sm-custom:h-[130px] sm:w-[324px] sm:h-auto flex flex-col items-start sm:flex-row sm:gap-4 mt-6 -mb-2">
					<CdnImage
						dataCy="user-image"
						src={user?.profile_image?.src || userBlackIcon}
						alt="avatar"
						className="w-[52px] h-[52px] lg:w-[62px] lg:h-[62px] rounded-full object-cover mb-2 lg:mb-0"
					/>
					<div className="flex flex-col gap-1 pt-1">
						<p data-cy="user-email" className="text-[11px] text-loginTextGrey">
							{user?.email}
						</p>
						<p
							data-cy="user-name"
							className="text-[24px] leading-6 tracking-tight font-extrabold lg:text-[29px]"
						>
							{user?.name} {user?.last_name}
						</p>
					</div>
				</div>
				<div className="flex items-center flex-1 justify-between sm:justify-end sm:gap-x-8 mt-4 mb-8">
					<button
						data-cy="user-following-button"
						className="flex flex-col items-center gap-1 mt-2 pointer-events-none"
					>
						<p className="text-[11px] text-loginTextGrey">Following</p>
						<p className="text-[24px] leading-6 tracking-tight font-extrabold lg:text-[32px]">
							{user?.following.length || 0}
						</p>
					</button>
					<Link to={`/settings/me`}>
						<button className="border-gray-300 border-[1px] bg-white hover:bg-gray-300 w-[102px] h-[52px] rounded-full flex justify-center items-center gap-1 mt-3 ">
							<img src={settingsIcon} alt="settings icon" className="w-4 h-4" />
							<p className="text-[13px] font-bold">Settings</p>
						</button>
					</Link>
				</div>
			</div>

			<div className="mt-3 w-full gap-4 md:gap-6 lg:gap-8">
				<div className="px-5 mt-4">
					<TabProfileNav
						savedRecipesCount={savedRecipesCount}
						createdRecipesCount={0}
						favouritesRecipesCount={favouritesRecipesCount}
						collectionsCount={totalCollections}
						activeTab={activeTab}
						setActiveTab={setActiveTab}
						isChef={false}
						resetSkip={resetSkip}
					/>
				</div>

				<div className="grid justify-center gap-y-10 gap-x-2 min-[375px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-[repeat(6,235px)]">
					{userSavedRecipes &&
						activeTab === 'saved' &&
						userSavedRecipes.data.map((recipe) => (
							<div data-cy="user-cards-saved-recipes" key={recipe.id}>
								<RecipesCard recipe={recipe} />
							</div>
						))}
					{userLikedRecipes &&
						activeTab === 'favourites' &&
						userLikedRecipes.data.map((recipe) => (
							<div data-cy="user-cards-favourites-recipes" key={recipe.id}>
								<RecipesCard recipe={recipe} />
							</div>
						))}
					{activeTab === 'collections' &&
						collections.map((collection, index) => (
							<Link
								to={`/profile/collection/${collection.uuid}`}
								data-cy="user-cards-collections"
								key={collection.id || index}
							>
								<CollectionCard collection={collection} />
							</Link>
						))}
				</div>
				{((favouritesRecipesCount > 0 && activeTab === 'favourites') ||
					(savedRecipesCount > 0 && activeTab === 'saved') ||
					(totalCollections > 0 && activeTab === 'collections')) && (
					<NavigationButtons
						currentPage={currentPage}
						totalPages={totalPages}
						handleNextClick={onHandleNext}
						handlePrevClick={onHandlePrevious}
					/>
				)}
			</div>
		</div>
	);
};
