import { useState } from 'react';

import cookingIcon from '@/assets/svg/cooking-hat.svg';
import { PopUp } from '@/components/common/PopUp';
import { SignUpModal } from '@/pages/signup-landing/modal/SignUpModal';

export const ComingSoon = () => {
	const [open, setOpen] = useState(false);

	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);

	return (
		<div className="font-helveticaNeue">
			<section className="flex flex-col items-center lg:w-[480px] lg:px-8 mx-2">
				<h1
					data-cy="comingsoon-title"
					className="w-[100%] lg:w-[385px] h-[57px] text-center text-[24px] leading-9 tracking-wider text-white font-bold"
				>
					COMING SOON
				</h1>
				<img
					src="/images/kitched.png"
					alt="Kitched Logo"
					className="max-w-full h-auto lg:max-w-[480px] mt-3"
					data-cy="comingsoon-logo"
				/>

				<p
					data-cy="comingsoon-description"
					className="w-[100%] lg:w-[385px] h-[57px] mt-8 text-center leading-5 tracking-widest text-white text-[14px]"
				>
					ALL YOUR FAVOURITE CREATORS, ALL YOUR FAVOURITE RECIPES, ALL IN ONE
					PLACE
				</p>

				<div className="w-full lg:max-w-[400px] mt-8 flex flex-col items-center gap-4 md:flex-row md:justify-center">
					<button
						data-cy="comingsoon-cookmore-button"
						className="flex items-center justify-center w-full md:w-[200px] h-[52px] rounded-full bg-white shadow-md hover:shadow-lg transform transition-all duration-300 ease-in-out hover:scale-105"
						onClick={onOpenModal}
					>
						<p className="text-[11px] text-brandOrange">Cook More</p>
						<img
							src={cookingIcon}
							alt="arrow-icon"
							className="w-4 h-4 ml-2 -mt-[2px]"
						/>
					</button>
				</div>

				<div>
					<PopUp open={open} onClose={onCloseModal}>
						<SignUpModal />
					</PopUp>
				</div>
			</section>
		</div>
	);
};
