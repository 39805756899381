import { useEffect, useState } from 'react';

import ChangePasswordSettings from './change-password-settings/ChangePasswordSettings';
import { CustomChoicesMenus } from './preferences-settings/CustomChoicesMenus';
import { FavouriteCuisinesMenu } from './preferences-settings/FavouriteCuisines';
import ProfileUserSettings from './profile-settings/ProfileUserSettings';
import UpdateProfileImage from './profile-settings/UpdateProfileImage';
import GeneralSettingsButtons from './settings-buttons/GeneralSettingsButtons';

import { Loader } from '@/components/common/Loader';
import { useAuth } from '@/context/useAuth';

export const SettingsPage = () => {
	const { user } = useAuth();
	const [newUserName, setNewUserName] = useState(user?.name || '');
	const [newUserLastName, setNewUserLastName] = useState(user?.last_name || '');

	const [selectConfigView, setSelectConfigView] = useState('GeneralSettings');

	useEffect(() => {
		setNewUserName(user?.name || '');
		setNewUserLastName(user?.last_name || '');
	}, [user]);

	if (!user)
		return (
			<div className="flex justify-center items-center h-[80vh]">
				<Loader size={4} />
			</div>
		);

	return (
		<div className="space-y-10 bg-slate-200 my-10">
			<div className="flex flex-col items-start p-5 gap-4 bg-white rounded-b-3xl  lg:flex-row lg:justify-between lg:items-center">
				<div className="flex items-center gap-2">
					<UpdateProfileImage user={user} />

					<div className="flex flex-col space-y-[-6px] -translate-y-[2px]">
						<span data-cy="user-email" className="text-slate-400">
							{user.email}
						</span>

						<span
							data-cy="user-name"
							className="text-2xl lg:text-3xl font-semibold"
						>
							{newUserName} {newUserLastName}
						</span>
					</div>
				</div>

				<div data-cy="general-settings-button">
					<GeneralSettingsButtons
						selectConfigView={selectConfigView}
						setSelectConfigView={setSelectConfigView}
					/>
				</div>
			</div>

			{selectConfigView === 'GeneralSettings' && (
				<div className="flex flex-col gap-y-6 items-center px-2 pb-10 lg:flex-row lg:justify-center lg:gap-x-10">
					<div className="w-full pt-10 px-4 min-[460px]:w-[85%] sm:w-2/3 lg:w-1/3 bg-white rounded-3xl">
						<ChangePasswordSettings user={user} />
					</div>
					<div className="w-full pt-10 px-4 min-[460px]:w-[85%] sm:w-2/3 lg:w-1/3 bg-white rounded-3xl">
						<ProfileUserSettings user={user} />
					</div>
				</div>
			)}

			{selectConfigView === 'Preferences' && (
				<div className="flex flex-col gap-y-6 items-center px-2 pb-10 lg:flex-row lg:justify-center lg:gap-x-10">
					<div className="w-full pt-10 px-4 min-[460px]:w-[85%] sm:w-2/3 lg:w-1/3 bg-white rounded-3xl">
						<CustomChoicesMenus user={user} />
					</div>
					<div className="w-full pt-10 px-4 min-[460px]:w-[85%] sm:w-2/3 lg:w-1/3 lg:min-h-[355px] bg-white rounded-3xl">
						<FavouriteCuisinesMenu user={user} />
					</div>
				</div>
			)}
		</div>
	);
};
