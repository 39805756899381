import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { InstagramPageDirection } from '@/interface/instagram.interfaces';
import { getInstagramPosts } from '@/service/api/instagram';

export const useFetchInstagramPosts = (chefId: number) => {
	const [direction, setDirection] = useState<InstagramPageDirection>();

	const {
		data: posts,
		error: instagramPostsError,
		isLoading: isLoadingInstagramPosts,
		isError: isErrorInstagramPosts,
		refetch: refetchInstagramPosts,
	} = useQuery({
		queryKey: ['instagram-posts', { chefId, direction }],
		queryFn: () => getInstagramPosts(chefId, direction),
	});

	return {
		posts,
		isLoadingInstagramPosts,
		isErrorInstagramPosts,
		instagramPostsError,
		refetchInstagramPosts,
		setDirection,
		direction,
	};
};
