import { AxiosError } from 'axios';

import { handleAxiosError } from '@/components/common/errorMessageForAxios/errorMessageForAxios';
import { MEAL_PLAN_ERRORS_MESSAGES } from '@/error/meal-plan-erros.enum';
import { IGetAllResponse } from '@/interface/get-all-response';
import { MealPlan, MealPlanDto } from '@/interface/mealPlan.interfaces';
import { IPagination } from '@/interface/pagination.interface';
import Api from '@/service';

const RESOURCE = 'meal-plan';

export const fetchUserMealPlans = async (
	options?: IPagination,
): Promise<IGetAllResponse<MealPlan>> => {
	try {
		const { data } = await Api.get<IGetAllResponse<MealPlan>>(`/${RESOURCE}`, {
			params: { ...options },
		});

		return data;
	} catch (error: unknown) {
		console.log({ error });
		const errorMessage = handleAxiosError(
			error,
			MEAL_PLAN_ERRORS_MESSAGES.GET_ALL_MEAL_PLAN_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const fetchMealPlansBetweenDates = async (
	startDate: Date,
	endDate: Date,
	options?: IPagination,
): Promise<IGetAllResponse<MealPlan>> => {
	try {
		const { data } = await Api.get<IGetAllResponse<MealPlan>>(
			`/${RESOURCE}/date?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
			{
				params: { ...options },
			},
		);

		return data;
	} catch (error: unknown) {
		console.log({ error });
		const errorMessage = handleAxiosError(
			error,
			MEAL_PLAN_ERRORS_MESSAGES.GET_ALL_MEAL_PLAN_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const removeMealPlan = async (id: number): Promise<void> => {
	try {
		await Api.delete(`/${RESOURCE}/${id}`);
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			MEAL_PLAN_ERRORS_MESSAGES.DELETE_MEAL_PLAN_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const createMealPlan = async (
	mealPlan: MealPlanDto,
): Promise<MealPlan> => {
	try {
		const { data } = await Api.post<MealPlan>(`/${RESOURCE}`, mealPlan);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			MEAL_PLAN_ERRORS_MESSAGES.CREATE_MEAL_PLAN_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const getMealPlanById = async (
	mealPlanId: string,
): Promise<MealPlan> => {
	try {
		const { data } = await Api.get<MealPlan>(`/${RESOURCE}/${mealPlanId}`);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError && error.response?.status === 404) {
			throw new Error(MEAL_PLAN_ERRORS_MESSAGES.NOT_FOUND);
		}
		throw new Error(MEAL_PLAN_ERRORS_MESSAGES.GET_MEAL_PLAN_BY_ID_ERROR);
	}
};

export const editMealPlan = async (
	id: number,
	updateMealPlan: MealPlanDto,
): Promise<MealPlan> => {
	try {
		const { data } = await Api.patch<MealPlan>(
			`/${RESOURCE}/${id}`,
			updateMealPlan,
		);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			MEAL_PLAN_ERRORS_MESSAGES.UPDATE_MEAL_PLAN_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};
