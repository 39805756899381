import { useState } from 'react';

import { Loader } from '@/components/common/Loader';
import { SelectableList } from '@/components/common/SelectableList';
import { User } from '@/context/interfaces';
import { useUserPreferences } from '@/hooks/user/useUserPreferences ';
import { RecipePreferences } from '@/interface/user.interfaces';

interface FavouriteCuisinesMenuProps {
	user: User;
}

export const FavouriteCuisinesMenu = ({ user }: FavouriteCuisinesMenuProps) => {
	const { handleRecipePreferences, isPendingPreferencesChange } =
		useUserPreferences(user);

	const [selectedItems, setSelectedItems] = useState<Set<number>>(
		new Set(
			user.recipePreferences?.map((rp) =>
				Object.values(RecipePreferences).indexOf(rp),
			) || [],
		),
	);

	const handleItemClick = (recipePref: RecipePreferences) => {
		handleRecipePreferences(recipePref);
	};

	return (
		<div className="relative">
			<div className="flex flex-col items-start">
				<div className="flex justify-between items-center w-full space-x-5 pb-5">
					<h3 className="text-xl 2xl:text-text-3xl font-semibold text-left w-full whitespace-nowrap">
						Favourite Cuisines
					</h3>
					<div
						data-cy="items-selected"
						className="inline-flex justify-center items-center rounded-full whitespace-nowrap pl-4 pr-4 py-[4px] text-sm font-semibold shadow-sm text-black bg-slate-50 border-none ring-none h-[53px]"
					>
						{selectedItems.size} SELECTED
					</div>
				</div>
				<div className="space-y-4 pt-5 pb-10">
					{isPendingPreferencesChange ? (
						<Loader size={4} />
					) : (
						<>
							<SelectableList
								items={Object.values(RecipePreferences)}
								renderItem={(recipePref) => (
									<span className="block px-2 py-2 text-sm">{recipePref}</span>
								)}
								selectedItems={selectedItems}
								setSelectedItems={setSelectedItems}
								onItemClick={handleItemClick}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
