import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { CheckboxSwitch } from '../../components/common/CheckboxSwitch';

import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { ShareSocialMediaComponent } from '@/components/common/ShareSocialMediaComponent';
import { ChefPresentationComponent } from '@/components/common/chef/ChefPresentationComponent';
import { IngredientsComponent } from '@/components/common/recipe/IngredientsComponent';
import { MethodComponent } from '@/components/common/recipe/MethodComponent';
import { NutricionalInformationComponent } from '@/components/common/recipe/NutricionalInformationComponent';
import { RecipeBasicInformation } from '@/components/recipe-detail/RecipeBasicInformation';
import { RecipeDetailImage } from '@/components/recipe-detail/RecipeDetailImage';
import { ScrollToTop } from '@/helper/scrollToTop';
import { upperCaseFirstLetter } from '@/helper/upperCaseFirstLetter';
import { useGetOneRecipeById } from '@/hooks/recipes/useGetOneRecipeById';
import { IIngredient } from '@/interface/ingredient.interfaces';
import {
	INutritionalInformation,
	IRecipeStep,
} from '@/interface/recipe.interfaces';
import { logRecipeView } from '@/service/analytics/analytics';

export const RecipeDetailPage = () => {
	const [checked, setChecked] = useState(false);
	const [open, setOpen] = useState(false);
	const [openTagsModal, setOpenTagsModal] = useState(false);
	const onOpenModal = () => setOpen(true);
	const onCloseModal = () => setOpen(false);
	const handleTagsModal = () => setOpenTagsModal(!openTagsModal);

	const { id } = useParams();
	const {
		recipesById,
		isLoadingRecipesById,
		isErrorRecipesById,
		errorRecipesById,
	} = useGetOneRecipeById(id as string);

	useEffect(() => {
		if (recipesById) {
			const chefFullName = `${recipesById.chef?.user?.name || 'Unknown'} ${
				recipesById.chef?.user?.last_name || 'Unknown'
			}`;
			logRecipeView(recipesById.uuid, recipesById.title, chefFullName);
		}
	}, [recipesById]);

	if (isLoadingRecipesById) {
		return (
			<div className="flex justify-center items-center h-[40vh]">
				<Loader size={4} />
			</div>
		);
	}

	if (isErrorRecipesById) {
		return (
			<div
				className="flex mt-20 justify-center items-center  flex-col w-full   gap-6"
				data-cy="recipe-detail-error"
			>
				<p className="text-black text-3xl sm:text-4xl text-center font-sans font-bold tracking-tighter w-[50%]">
					{errorRecipesById?.message}
				</p>
			</div>
		);
	}

	const difficulty = upperCaseFirstLetter(recipesById?.difficulty as string);

	const displayedTags = recipesById?.tags?.slice(0, 5);

	return (
		<div
			data-cy="recipe-detail-page"
			className=" mx-4 sm:mx-10 my-10 lg:flex lg:items-start lg:gap-x-10 font-helveticaNeue"
		>
			<ScrollToTop />
			<div className="hidden lg:block lg:w-2/5">
				{recipesById && (
					<RecipeDetailImage
						onOpenPopUp={onOpenModal}
						mediaUrl={recipesById.instagramInformation?.media_url as string}
						isVideo={recipesById.instagramInformation?.media_type === 'VIDEO'}
						recipe={recipesById}
					/>
				)}
			</div>

			<div className="flex flex-col min-[500px]:items-center gap-y-10 lg:w-3/5 lg:items-start ">
				<div className="xl:flex xl:w-full">
					<ChefPresentationComponent
						chef={recipesById?.chef}
						instagramLink={
							recipesById?.instagramInformation?.permalink as string
						}
						profile_image={
							recipesById?.chef?.user?.profile_image?.src as string
						}
					/>

					<div className="xl:flex-1"></div>

					<div className="hidden xl:flex xl:items-center xl:p-4 xl:bg-slate-100 xl:rounded-3xl xl:gap-x-4 xl:buttons-shape">
						<div className="flex flex-col">
							<span className="text-yellow-400 font-semibold text-2xl ">
								{difficulty}
							</span>
							<span>I cooked this recipe</span>
						</div>
						<CheckboxSwitch
							checked={checked}
							onChange={() => setChecked(!checked)}
						/>
					</div>
				</div>

				<RecipeBasicInformation
					title={recipesById?.title as string}
					time={recipesById?.time as string}
					difficulty={recipesById?.difficulty as string}
					servings={recipesById?.servings as string}
					likes={recipesById?.likes as number}
					views={recipesById?.views?.views as number}
				/>

				<div className="hidden md:flex flex-wrap gap-2 text-white">
					{recipesById?.tags?.map((tag, index) => (
						<Link
							data-cy="card-info-tag"
							to={`/search/tag-${tag.name}`}
							key={index}
							className="buttons-shape bg-brandOrange hover:bg-brandOrange/80"
						>
							{tag.name}
						</Link>
					))}
				</div>

				<div className="flex flex-wrap gap-2 text-white md:hidden">
					{displayedTags?.map((tag, index) => (
						<Link
							to={`/search/tag-${tag.name}`}
							key={index}
							className="buttons-shape bg-brandOrange hover:bg-brandOrange/80"
						>
							{tag.name}
						</Link>
					))}
					{recipesById?.tags && recipesById?.tags?.length > 5 && (
						<div className="flex items-center justify-center">
							<button
								onClick={handleTagsModal}
								className="p-2 bg-brandGreen rounded text-white"
							>
								Show All Tags
							</button>
						</div>
					)}

					<PopUp open={openTagsModal} onClose={handleTagsModal}>
						<div className="flex flex-wrap gap-2 text-white">
							{recipesById?.tags?.map((tag, index) => (
								<Link
									to={`/search/tag-${tag.name}`}
									key={index}
									className="buttons-shape bg-brandOrange hover:bg-brandOrange/80"
								>
									{tag.name}
								</Link>
							))}
						</div>
						<div className="flex items-center justify-center mt-4">
							<button
								onClick={handleTagsModal}
								className="p-2 bg-brandRed rounded text-white"
							>
								Show Less
							</button>
						</div>
					</PopUp>
				</div>

				<div className="md:w-[500px] lg:hidden">
					{recipesById && (
						<RecipeDetailImage
							onOpenPopUp={onOpenModal}
							mediaUrl={recipesById.instagramInformation?.media_url as string}
							isVideo={recipesById.instagramInformation?.media_type === 'VIDEO'}
							recipe={recipesById}
						/>
					)}
				</div>

				<div className="flex items-center p-4 bg-slate-100 rounded-3xl min-[500px]:self-end min-[500px]:gap-x-4 xl:hidden">
					<div className="flex flex-col">
						<span className="text-yellow-400 font-semibold text-2xl">
							{difficulty}
						</span>
						<span>I cooked this recipe</span>
					</div>
					<div className="flex-1 min-[500px]:flex-none"></div>
					<CheckboxSwitch
						checked={checked}
						onChange={() => setChecked(!checked)}
					/>
				</div>

				<IngredientsComponent
					ingredients={recipesById?.ingredients as IIngredient[]}
				/>

				<MethodComponent
					instructions={recipesById?.instructions as IRecipeStep[]}
				/>

				{recipesById?.nutritionalInformation && (
					<NutricionalInformationComponent
						nutrients={
							recipesById?.nutritionalInformation as INutritionalInformation
						}
					/>
				)}
			</div>

			<PopUp open={open} onClose={onCloseModal} topCss="20vh">
				<ShareSocialMediaComponent />
			</PopUp>
		</div>
	);
};
