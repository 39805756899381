import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SwiperSlide } from 'swiper/react';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import { Loader } from '@/components/common/Loader';
import { Title } from '@/components/common/Title/Title';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { RecipeDinamicCardSkeletond } from '@/components/common/recipe/masonry-recipe/RecipeDinamicCardSkeleton';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { Slider } from '@/components/common/slider/Slider';
import { useGetRecipesByUserPreferences } from '@/hooks/recipes/useGetRecipesByUserPreferences';
import { usePagination } from '@/hooks/usePagination';

export const RecipesForYouByPreferences = () => {
	const [skip, setSkip] = useState(0);
	const [sliderStyle, setSliderStyle] = useState<string | null>(null);

	const take = 18;
	const {
		userPreferenceRecipes,
		totalEntities,
		isLoadingUserPreferenceRecipes,
		isErrorUserPreferenceRecipes,
		refetchUserPreferenceRecipes,
	} = useGetRecipesByUserPreferences({ take, skip });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});

	useEffect(() => {
		refetchUserPreferenceRecipes();
	}, [skip]);

	if (isLoadingUserPreferenceRecipes)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (isErrorUserPreferenceRecipes) return <></>;

	return (
		<div data-cy="recipesforyou-section" className="w-full pt-5 xl:pt-0">
			<div className="flex flex-col items-start gap-y-3 px-5 min-[500px]:flex-row min-[500px]:justify-between min-[500px]:items-center">
				<Title
					textTitle="Recipes"
					colorText="brandRed"
					titleWithColor="for you"
				/>
				<Link to={`recipes`} className="place-self-end w-[47%] max-w-[180px]">
					<button className="w-full flex items-center justify-between py-3 px-4 bg-brandOrange rounded-full  transition-all hover:text-brandOrange hover:bg-white hover:ring-2 hover:ring-brandOrange hover:cursor-pointer font-helveticaNeue tracking-[0.02em]">
						<span className="text-sm text-white">All Recipes</span>
						<img
							src={arrowUpRight}
							alt="arrow-up-right-icon"
							className="hidden min-[345px]:block"
						/>
					</button>
				</Link>
			</div>

			{totalEntities === 0 ? (
				<div className="px-8 mt-6 ">
					<RecipeDinamicCardSkeletond
						height="normal"
						message="No recipes found"
					/>
				</div>
			) : (
				<div className="flex flex-col justify-center items-center lg:flex-row lg:items-center lg:gap-x-4">
					<Slider cardsType={{ type: 'SMALL' }}>
						{userPreferenceRecipes?.map((recipes) => (
							<SwiperSlide
								key={recipes.id}
								className={sliderStyle == recipes.uuid ? 'z-10' : 'z-0'}
							>
								<RecipesCard
									recipe={recipes}
									setSliderStyle={setSliderStyle}
									isOnCarusel={true}
									resizeInFullScreen={true}
								/>
							</SwiperSlide>
						))}
					</Slider>
					<div className="lg:-translate-y-20">
						<NavigationButtons
							currentPage={currentPage}
							totalPages={totalPages}
							handleNextClick={onHandleNext}
							handlePrevClick={onHandlePrevious}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
