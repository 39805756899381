import { Link } from 'react-router-dom';

import {
	CreatorsIcon,
	PlannerIcon,
	RecipeIcon,
	SavedRecipeIcon,
} from './LinksIcons';

import { useAuth } from '@/context/useAuth';
import useWindowWidth from '@/hooks/useWindowWidth';

interface NavbarLinkComponentProps {
	redirectTo: string;
	children: JSX.Element;
	text: string;
	scrolled: boolean;
	path: string;
}
function NavbarLinkComponent({
	redirectTo,
	children,
	text,
	scrolled,
	path,
}: NavbarLinkComponentProps) {
	function handleRedirect() {
		if (text === 'Saved') {
			return redirectTo === '/chef/profile'
				? '/chef/profile?tab=saved'
				: 'user/me?tab=saved';
		}
		return redirectTo;
	}
	return (
		<Link
			to={handleRedirect()}
			className="flex justify-center items-center gap-1 cursor-pointer"
			data-cy="navbar-link"
		>
			{children}
			<span
				className={`hover:text-brandRed w-full cursor-pointer text-[16px] ${
					!scrolled && path === '/' && 'text-white'
				}`}
			>
				{text}
			</span>
		</Link>
	);
}

export const NavbarLinks = ({
	scrolled,
	path,
}: {
	scrolled: boolean;
	path: string;
}) => {
	const { user } = useAuth();
	const windowWidth = useWindowWidth();

	return (
		<div
			className={`flex items-center justify-center font-helveticaNeue text-[14px] font-normal w-full gap-6 ${
				windowWidth < 1024 && 'hidden'
			}`}
		>
			<NavbarLinkComponent
				redirectTo="/recipes"
				children={<RecipeIcon scrolled={scrolled} path={path} />}
				text="Recipes"
				scrolled={scrolled}
				path={path}
			/>
			<NavbarLinkComponent
				redirectTo="/chefs"
				children={<CreatorsIcon scrolled={scrolled} path={path} />}
				text="Creators"
				scrolled={scrolled}
				path={path}
			/>
			<NavbarLinkComponent
				redirectTo={user?.chef?.id ? '/chef/profile' : '/user/me'}
				children={<SavedRecipeIcon scrolled={scrolled} path={path} />}
				text="Saved"
				scrolled={scrolled}
				path={path}
			/>

			<NavbarLinkComponent
				redirectTo="/planner"
				children={<PlannerIcon scrolled={scrolled} path={path} />}
				text="Planner"
				scrolled={scrolled}
				path={path}
			/>
		</div>
	);
};
