import { Calendar } from './Calendar';

import { MyListSavedRecipe } from '@/components/saved-recipes/MyListSavedRecipe';

export const Planner = () => {
	return (
		<div>
			<Calendar />
			<MyListSavedRecipe />
		</div>
	);
};
