import { useMutation, useQueryClient } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { MealPlan } from '@/interface/mealPlan.interfaces';
import { removeMealPlan } from '@/service/api/mealPlan';

export const useDeleteMealPlan = () => {
	const queryClient = useQueryClient();
	const { mutateAsync: deleteMealPlan, isPending: isDeletingMealPlan } =
		useMutation({
			mutationFn: removeMealPlan,
			onMutate: async (mealPlanId: number) => {
				await queryClient.cancelQueries({ queryKey: ['mealPlans'] });
				const previousMealPlans = queryClient.getQueryData<MealPlan[]>([
					'mealPlans',
				]);

				if (previousMealPlans) {
					queryClient.setQueryData(
						['mealPlans'],
						previousMealPlans.filter((mealPlan) => mealPlan.id !== mealPlanId),
					);
				}

				return { previousMealPlans, mealPlanId };
			},
			onError: (error: Error, _variables, context) => {
				if (context?.previousMealPlans) {
					queryClient.setQueryData(['mealPlans'], context.previousMealPlans);
				}
				notifyError(`${error.message}`);
			},
			onSuccess: () => {
				queryClient.invalidateQueries({ queryKey: ['mealPlans'] });
				notifySuccess('Meal plan deleted successfully');
			},
			onSettled: () => {
				queryClient.invalidateQueries({ queryKey: ['mealPlans'] });
			},
		});

	return { deleteMealPlan, isDeletingMealPlan };
};
