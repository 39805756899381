import { EventInput } from '@fullcalendar/core/index.js';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import { QueryFunction, QueryKey } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { useWindowSize } from 'react-use';

import { RenderEventContent } from './CalendarCards';
import './calendar.css';
import { fetchMealPlanData, getDate } from './calendarFunctions';

import { useFetchQuerys } from '@/helper/useFetchQuerys';
import { IGetAllResponse } from '@/interface/get-all-response';
import { MealPlan } from '@/interface/mealPlan.interfaces';
import { fetchMealPlansBetweenDates } from '@/service/api/mealPlan';

export const Calendar = () => {
	const calendarRef = useRef<FullCalendar>(null);
	const { today, endDate } = getDate();
	const { width } = useWindowSize();
	const [numDays, setNumDays] = useState(4);

	useEffect(() => {
		if (width >= 1536) {
			setCustomView('customFourDay');
			setNumDays(4);
		} else if (width >= 1024) {
			setCustomView('customThreeDay');
			setNumDays(3);
		} else if (width >= 640) {
			setCustomView('customTwoDay');
			setNumDays(2);
		} else {
			setCustomView('customOneDay');
			setNumDays(1);
		}
	}, [width]);

	const [dateRange, setDateRange] = useState({
		startDate: new Date(new Date().setDate(new Date().getDate() - 1)),
		endDate: new Date(new Date().setDate(new Date().getDate() + numDays)),
	});

	const handleToolbarNext = () => {
		const calendarApi = calendarRef.current?.getApi();
		if (calendarApi) {
			calendarApi.next();
		}
		setDateRange((prev) => {
			const newStartDate = new Date(prev.startDate);
			newStartDate.setDate(newStartDate.getDate() + numDays);
			const newEndDate = new Date(prev.endDate);
			newEndDate.setDate(newEndDate.getDate() + numDays);
			return { startDate: newStartDate, endDate: newEndDate };
		});
	};

	const handleToolbarPrev = () => {
		const calendarApi = calendarRef.current?.getApi();
		if (calendarApi) {
			calendarApi.prev();
		}
		setDateRange((prev) => {
			const newStartDate = new Date(prev.startDate);
			newStartDate.setDate(newStartDate.getDate() - numDays);
			const newEndDate = new Date(prev.endDate);
			newEndDate.setDate(newEndDate.getDate() - numDays);
			return { startDate: newStartDate, endDate: newEndDate };
		});
	};

	const fetchMealPlansWrapped: QueryFunction<
		IGetAllResponse<MealPlan>,
		QueryKey
	> = () => {
		const { startDate, endDate } = dateRange;
		return fetchMealPlansBetweenDates(startDate, endDate);
	};

	const { data } = useFetchQuerys(fetchMealPlansWrapped, [
		'meal-plans',
		dateRange.startDate,
		dateRange.endDate,
	]);

	const [events, setEvents] = useState<EventInput[]>([]);
	const [customView, setCustomView] = useState('customFourDay');

	useEffect(() => {
		fetchMealPlanData({
			mealPlans: data?.data,
			setEvents: setEvents,
		});
	}, [data, dateRange, setEvents]);

	useEffect(() => {
		if (calendarRef.current) {
			const calendarApi = calendarRef.current.getApi();
			calendarApi.changeView(customView);
		}
	}, [customView]);

	return (
		<div className="">
			<div className="p-6">
				<div className="flex items-center justify-between pb-5 lg:pb-0 lg:translate-y-10 w-[440px]">
					<h3
						data-cy="meal-planner-title"
						className="text-5xl text-center font-semibold md:text-left 2xl:text-5xl tracking-[-0.04em]"
					>
						Meal <span className={`text-brandRed`}>Planner </span>
					</h3>
				</div>

				<FullCalendar
					plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
					initialView={customView}
					ref={calendarRef}
					views={{
						customFourDay: {
							type: 'dayGrid',
							duration: { days: 4 },
							titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
						},
						customThreeDay: {
							type: 'dayGrid',
							duration: { days: 3 },
							titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
						},
						customTwoDay: {
							type: 'dayGrid',
							duration: { days: 2 },
							titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
						},
						customOneDay: {
							type: 'dayGrid',
							duration: { days: 1 },
							titleFormat: { year: 'numeric', month: 'short', day: 'numeric' },
						},
					}}
					headerToolbar={{
						left: 'prev',
						center: 'title',
						right: 'next',
					}}
					customButtons={{
						prev: {
							click: handleToolbarPrev,
						},
						next: {
							click: handleToolbarNext,
						},
					}}
					validRange={{
						start: today.toISOString(),
						end: endDate.toISOString(),
					}}
					dayHeaderFormat={{ weekday: 'long', day: '2-digit', month: 'long' }}
					selectable={true}
					selectMirror={true}
					events={events}
					eventContent={(eventContent) =>
						RenderEventContent({ eventContent, events, setEvents })
					}
					height={'auto'}
					contentHeight={'100px'}
				/>
			</div>
		</div>
	);
};
