import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Loader } from '@/components/common/Loader';
import { RecipesCard } from '@/components/common/recipe/recipe-card/RecipesCard';
import { useGetCollectionByUuid } from '@/hooks/collection/useGetOneCollectionByUuid';
import { CollectionHeader } from '@/pages/user/collections/header/CollectionHeader';

export const CollectionDetailPage = () => {
	const { collectionUuid } = useParams<string>();
	const navigate = useNavigate();

	const { collection, isLoading, isError, error } = useGetCollectionByUuid(
		collectionUuid as string,
	);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (isLoading) {
		return (
			<div className="flex justify-center items-center h-[40vh]">
				<Loader size={2} />
			</div>
		);
	}

	if (isError) {
		return (
			<div className="flex justify-center items-center h-[40vh]">
				<p className="text-red-500">Error: {error?.message}</p>
			</div>
		);
	}

	if (!collection) {
		return (
			<div className="flex justify-center items-center h-[40vh]">
				<p className="text-gray-500">Collection not found</p>
			</div>
		);
	}

	return (
		<div className="flex flex-col items-center font-helveticaNeue mx-10 my-10">
			<CollectionHeader collection={collection} onBack={() => navigate(-1)} />
			<div className="grid justify-center gap-y-10 gap-x-2 min-[375px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-[repeat(6,235px)]">
				{collection.recipes?.map((recipe) => (
					<div data-cy="user-cards-collection-recipes" key={recipe.id}>
						<RecipesCard recipe={recipe} hideIcons />
					</div>
				))}
			</div>
		</div>
	);
};
