export const cuisineTags = [
	{
		id: 1,
		name: 'Australian',
		param: 'australian',
		isActive: false,
	},
	{
		id: 2,
		name: 'Asian',
		param: 'asian',
		isActive: false,
	},
	{
		id: 3,
		name: 'British',
		param: 'british',
		isActive: false,
	},
	{
		id: 4,
		name: 'Canadian',
		param: 'canadian',
		isActive: false,
	},
	{
		id: 5,
		name: 'Caribbean',
		param: 'caribbean',
		isActive: false,
	},
	{
		id: 6,
		name: 'American',
		param: 'american',
		isActive: false,
	},
	{
		id: 7,
		name: 'African',
		param: 'african',
		isActive: false,
	},
	{
		id: 8,
		name: 'Chinese',
		param: 'chinese',
		isActive: false,
	},
	{
		id: 9,
		name: 'German',
		param: 'german',
		isActive: false,
	},
	{
		id: 10,
		name: 'Corean',
		param: 'corean',
		isActive: false,
	},
];

export const mealTypeTags = [
	{
		id: 1,
		name: 'Aperitif',
		param: 'aperitif',
		isActive: false,
	},
	{
		id: 2,
		name: 'Beverages',
		param: 'beverages',
		isActive: false,
	},
	{
		id: 3,
		name: 'Breakfast',
		param: 'breakfast',
		isActive: false,
	},
	{
		id: 4,
		name: 'Brunch',
		param: 'brunch',
		isActive: false,
	},
	{
		id: 5,
		name: 'Condiments & Sauces',
		param: 'condiments&Sauces',
		isActive: false,
	},
	{
		id: 6,
		name: 'Dessert',
		param: 'dessert',
		isActive: false,
	},
	{
		id: 7,
		name: 'Dinner',
		param: 'dinner',
		isActive: false,
	},
	{
		id: 8,
		name: 'Juice',
		param: 'juice',
		isActive: false,
	},
	{
		id: 9,
		name: 'Salad',
		param: 'salad',
		isActive: false,
	},
	{
		id: 10,
		name: 'Lunch',
		param: 'lunch',
		isActive: false,
	},
];

export const dietTags = [
	{
		id: 1,
		name: 'Vegan',
		param: 'vegan',
		isActive: false,
	},
	{
		id: 2,
		name: 'Lacto-Vegetarian',
		param: 'lactovegetarian',
		isActive: false,
	},
	{
		id: 3,
		name: 'Pescatarian',
		param: 'pescatarian',
		isActive: false,
	},
	{
		id: 4,
		name: 'Low-Carb',
		param: 'lowCarb',
		isActive: false,
	},
	{
		id: 5,
		name: 'Keto',
		param: 'keto',
		isActive: false,
	},
	{
		id: 6,
		name: 'Gluten-free',
		param: 'glutenfree',
		isActive: false,
	},
];

export const searchBarTags = [
	{
		id: 1,
		name: 'Healthy',
		param: 'healthy',
		isActive: false,
	},
	{
		id: 2,
		name: 'Vegetarian',
		param: 'vegetarian',
		isActive: false,
	},
	{
		id: 3,
		name: 'Pasta',
		param: 'pasta',
		isActive: false,
	},
	{
		id: 4,
		name: 'Quick',
		param: 'quick',
		isActive: false,
	},
	{
		id: 5,
		name: 'Vegan',
		param: 'vegan',
		isActive: false,
	},
	{
		id: 6,
		name: 'High Protein',
		param: 'highprotein',
		isActive: false,
	},
	{
		id: 7,
		name: 'Easy',
		param: 'easy',
		isActive: false,
	},
	{
		id: 8,
		name: 'Chicken',
		param: 'chicken',
		isActive: false,
	},
	{
		id: 10,
		name: 'Mexican',
		param: 'mexican',
		isActive: false,
	},
	{
		id: 11,
		name: 'Asian',
		param: 'asian',
		isActive: false,
	},
	{
		id: 12,
		name: 'Dessert',
		param: 'dessert',
		isActive: false,
	},
];
