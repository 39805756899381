import { useQuery } from '@tanstack/react-query';

import { getAllRandomRecipes } from '@/service/api/recipe';

export const useGetAllRandomRecipes = (take: number, skip: number) => {
	const {
		data: allRandomRecipes,
		isLoading: isLoadingAllRandomRecipes,
		isError: isErrorAllRandomRecipes,
		error: errorAllRandomRecipes,
	} = useQuery({
		queryKey: ['all-random-recipes', take, skip],
		queryFn: () => getAllRandomRecipes(take, skip),
		retry: false,
		staleTime: 10000,
	});

	return {
		allRandomRecipes,
		isLoadingAllRandomRecipes,
		isErrorAllRandomRecipes,
		errorAllRandomRecipes,
	};
};
