import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { searchBarTags } from '@/assets/mock/tags_menu_items.mock';

interface TagsSearchBarProps {
	onHandleSearchTagByQuery?: (tagsQuery: string) => void;
	onHandleSearchTagByParam?: (tagParam: string) => void;
	sectionName?: string;
}
export const TagsSearchBar = ({
	onHandleSearchTagByQuery,
	onHandleSearchTagByParam,
	sectionName,
}: TagsSearchBarProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const params = new URLSearchParams(location.search);
	const currentTag = params.get(sectionName ?? '');
	if (currentTag) {
		(onHandleSearchTagByQuery &&
			onHandleSearchTagByQuery(`tag[]=${currentTag}`)) ||
			(onHandleSearchTagByParam &&
				onHandleSearchTagByParam(`tag-${currentTag}`));
	}
	const [searchBarTagsState, setSearchBarTagsState] = useState(searchBarTags);

	const onSetSearchBarTag = (param: string, id: number) => {
		setSearchBarTagsState((prevTags) =>
			prevTags.map((tag) =>
				tag.id === id
					? { ...tag, isActive: true }
					: { ...tag, isActive: false },
			),
		);

		(onHandleSearchTagByQuery && onHandleSearchTagByQuery(`tag[]=${param}`)) ||
			(onHandleSearchTagByParam && onHandleSearchTagByParam(`tag-${param}`));

		if (sectionName) {
			params.set(`${sectionName}`, param);
			navigate({ search: params.toString() });
		}
	};

	useEffect(() => {
		if (currentTag) {
			const currentTagObj = searchBarTags.find(
				(tag) => tag.param === currentTag,
			);
			if (currentTagObj && currentTagObj.id !== undefined) {
				onSetSearchBarTag(currentTag, currentTagObj.id);
			}
		} else {
			setSearchBarTagsState((prevTags) =>
				prevTags.map((tag) => ({ ...tag, isActive: false })),
			);
		}
	}, [location.search, sectionName]);

	const onClearSearchBarTags = () => {
		setSearchBarTagsState((prevTags) =>
			prevTags.map((tag) => ({ ...tag, isActive: false })),
		);
		(onHandleSearchTagByQuery && onHandleSearchTagByQuery('')) ||
			(onHandleSearchTagByParam && onHandleSearchTagByParam(''));

		if (sectionName) {
			params.delete(`${sectionName}`);
			navigate({ search: params.toString() });
		}
	};

	return (
		<div
			data-cy="tag-search-bar"
			className="hidden md:flex flex-wrap gap-2 justify-center md:justify-start"
		>
			{searchBarTagsState.map((tag) => (
				<button
					data-cy="recipe-nav-sort-item"
					key={tag.id}
					className="block grow sm:grow-0 buttons-shape min-w-[110px] text-sm  text-slate-700 bg-slate-100  border-[1px] border-slate-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10 "
					onClick={() => onSetSearchBarTag(tag.param, tag.id)}
					style={{
						backgroundColor: tag.isActive ? 'rgb(232 201 136 / 0.1)' : '',
						borderColor: tag.isActive ? 'rgb(232 201 136)' : '',
						color: tag.isActive ? 'rgb(232 201 136)' : '',
					}}
				>
					{tag.name}
				</button>
			))}
			<button
				className="block grow sm:grow-0  text-sm text-green-700 bg-green-100 buttons-shape border-[1px] border-green-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10"
				onClick={onClearSearchBarTags}
			>
				Clear all
			</button>
		</div>
	);
};
