import kitchedLogo from '@assets/svg/kitched-logo-login.svg';

export const TitleLogin = () => {
	return (
		<div
			className="w-[273px] h-[143px] text-5xl text-center mx-auto mb-1.5"
			data-cy="login-title"
		>
			<h1 className="text-[52px] w-full font-semibold -mt-2 flex flex-col items-center">
				<p>Log In to</p>
				<img src={kitchedLogo} alt="logo" className="mt-3 w-[190px] h-[45px]" />
			</h1>
			<p className="w-[250px] h-[27px] mt-4 ml-4 leading-5 text-[15px] text-loginTextGrey">
				All your favourite creators, all your favourite recipes, all in one
				place
			</p>
		</div>
	);
};
