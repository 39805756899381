import { useQuery } from '@tanstack/react-query';

import { IPagination } from '@/interface/pagination.interface';
import { OrderByDate, getAllRecipesByDate } from '@/service/api/recipe';

export const useGetAllRecipesByDate = (
	order: OrderByDate,
	options: IPagination,
) => {
	const {
		data: recipesByDate,
		isLoading: isLoadingRecipesByDate,
		isError: isErrorRecipesByDate,
		error: errorRecipesByDate,
		refetch: refetchRecipesByDate,
	} = useQuery({
		queryKey: ['recipes-by-date', order],
		queryFn: () => getAllRecipesByDate(order, options),
	});

	return {
		recipesByDate: recipesByDate?.data,
		totalEntities: recipesByDate?.total as number,
		refetchRecipesByDate,
		isLoadingRecipesByDate,
		isErrorRecipesByDate,
		errorRecipesByDate,
	};
};
