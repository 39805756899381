import algoliasearch from 'algoliasearch';
import { useEffect, useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch';
import { useParams } from 'react-router-dom';

import { AlgoliaPagination } from '@/components/algolia/AlgoliaPagination';
import { AlgoliaResults } from '@/components/algolia/AlgoliaResults';
import { AlgoliaSearchBar } from '@/components/algolia/AlgoliaSearchBar';
import { CustomAlgoliaSearchBox } from '@/components/algolia/CustomAlgoliaSearchBox';

const searchClient = algoliasearch(
	import.meta.env.VITE_ALGOLIA_APP_ID ?? '',
	import.meta.env.VITE_ALGOLIA_SEARCH_API_KEY ?? '',
);
const algoliaIndex = import.meta.env.VITE_ALGOLIA_INDEX ?? 'demo_kitched_index';

export const SearchResults = () => {
	const { param } = useParams();
	const [searchWord, setSearchWord] = useState<string>();

	const recipesPerPage = 18;

	useEffect(() => {
		if (param?.startsWith('tag-')) {
			setSearchWord(param.substring(4));
		} else {
			setSearchWord(param);
		}
	}, [param]);

	return (
		<InstantSearch indexName={algoliaIndex} searchClient={searchClient}>
			<Configure hitsPerPage={recipesPerPage} />

			<div className="flex flex-col items-center px-4 lg:items-start sm:px-8 gap-y-4 mt-6">
				<h3
					data-cy="search-results-title"
					className="font-semibold text-3xl md:text-4xl 2xl:text-5xl"
				>
					What kind of <span className="text-brandRed">recipes</span> are you
					looking for?
				</h3>
				<div className="flex flex-col">
					<CustomAlgoliaSearchBox searchWord={searchWord as string} />
					<span className="text-xs text-slate-300 ml-2">
						Search by name or tag
					</span>
				</div>

				<AlgoliaSearchBar />

				<AlgoliaResults />

				<AlgoliaPagination />
			</div>
		</InstantSearch>
	);
};
