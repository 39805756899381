import { useMutation, useQueryClient } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { putEarlyUserByEmail } from '@/service/api/earlyAccessUser';

const usePutUserByEmail = () => {
	const queryClient = useQueryClient();

	const earlyUsersMutation = useMutation({
		mutationFn: putEarlyUserByEmail,
		onSuccess: () => {
			notifySuccess(
				'You have correctly applied to become a chef. We will submit your application to our administrators',
			);
			queryClient.invalidateQueries({ queryKey: ['early-users-become-chef'] });
		},
		onError: () =>
			notifyError('An error occurred when requesting to be a creator'),
	});

	return { earlyUsersMutation };
};

export default usePutUserByEmail;
