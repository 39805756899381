import { useEffect, useState } from 'react';

import {
	cuisineTags,
	dietTags,
	mealTypeTags,
} from '@/assets/mock/tags_menu_items.mock';
import { PopUp } from '@/components/common/PopUp';

import checkedIcon from '@assets/svg/checked-box-icon.svg';

interface TagsPopUpProps {
	open: boolean;
	onHandleSearchTag: (tagsParams: string) => void;
	onCloseModal: () => void;
}

export const TagsSearchMenuPopUp = ({
	onHandleSearchTag,
	open,
	onCloseModal,
}: TagsPopUpProps) => {
	const [cuisineTag, setSelectCusineseTag] = useState<string>('');
	const [dietTag, setSelectDietTag] = useState<string>('');
	const [mealTypeTag, setSelectMealTypeTag] = useState<string>('');

	useEffect(() => {
		const selectedTags: string[] = [cuisineTag, dietTag, mealTypeTag];
		const queryParts: string[] = [];
		selectedTags.forEach((tag) => {
			if (tag) {
				queryParts.push(`tag[]=${tag}`);
			}
		});

		onHandleSearchTag(queryParts.join('&') || '');
	}, [cuisineTag, dietTag, mealTypeTag]);

	return (
		<PopUp open={open} onClose={onCloseModal}>
			<div
				data-cy="tags-pop-up"
				className=" w-full flex flex-col gap-y-6 sm:w-[480px] sm:h-[600px] p-6 overflow-y-scroll scrollbar-webkit"
			>
				<div className="border-b-[1px] border-b-slate-200 pb-8">
					<h4 className="text-2xl font-semi-bold">Tags</h4>
					<p className="text-sm text-slate-500">
						Select your requirements to personalise your experience
					</p>
				</div>
				<div className="flex flex-col gap-y-4">
					<div className="flex justify-between items-end">
						<h5 className="text-black text-2xl ">Cuisine</h5>
						<span
							className="text-xs uppercase text-slate-400 cursor-pointer"
							onClick={() => setSelectCusineseTag('')}
						>
							clear all
						</span>
					</div>
					<div className="flex flex-wrap gap-4">
						{cuisineTags.map((tag) => {
							const isSelected = tag.param === cuisineTag;
							return (
								<button
									data-cy="recipe-nav-sort-item"
									key={tag.id}
									className="block grow sm:grow-0 px-4 py-2.5 text-sm text-slate-700 bg-slate-100 rounded-2xl border-[1px] border-slate-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10"
									onClick={() => setSelectCusineseTag(tag.param)}
									style={{
										backgroundColor: isSelected ? 'rgb(232 201 136 / 0.1)' : '',
										borderColor: isSelected ? 'rgb(232 201 136)' : '',
										color: isSelected ? 'rgb(232 201 136)' : '',
									}}
								>
									{tag.name}
								</button>
							);
						})}
					</div>
				</div>
				<div className="flex flex-col gap-y-4">
					<div className="flex justify-between items-end">
						<h5 className="text-black text-2xl ">Meal Type</h5>
						<span
							className="text-xs uppercase text-slate-400 cursor-pointer"
							onClick={() => setSelectMealTypeTag('')}
						>
							clear all
						</span>
					</div>
					<div className="flex flex-wrap gap-4">
						{mealTypeTags.map((tag) => {
							const isSelected = tag.param === mealTypeTag;
							return (
								<button
									data-cy="recipe-nav-sort-item"
									key={tag.id}
									className="block grow sm:grow-0 px-4 py-2.5 text-sm text-slate-700 bg-slate-100 rounded-2xl border-[1px] border-slate-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10"
									onClick={() => setSelectMealTypeTag(tag.param)}
									style={{
										backgroundColor: isSelected ? 'rgb(232 201 136 / 0.1)' : '',
										borderColor: isSelected ? 'rgb(232 201 136)' : '',
										color: isSelected ? 'rgb(232 201 136)' : '',
									}}
								>
									{tag.name}
								</button>
							);
						})}
					</div>
				</div>
				<div className="flex flex-col gap-y-4">
					<div className="flex justify-between items-end">
						<h5 className="text-black text-2xl ">Diet</h5>
						<span
							className="text-xs uppercase text-slate-400 cursor-pointer"
							onClick={() => setSelectDietTag('')}
						>
							clear all
						</span>
					</div>
					<div className="flex flex-wrap gap-4">
						{dietTags.map((tag) => {
							const isSelected = tag.param === dietTag;
							return (
								<button
									data-cy="recipe-nav-sort-item"
									key={tag.id}
									className="block grow sm:grow-0 px-4 py-2.5 text-sm text-slate-700 bg-slate-100 rounded-2xl border-[1px] border-slate-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10"
									onClick={() => setSelectDietTag(tag.param)}
									style={{
										backgroundColor: isSelected ? 'rgb(232 201 136 / 0.1)' : '',
										borderColor: isSelected ? 'rgb(232 201 136)' : '',
										color: isSelected ? 'rgb(232 201 136)' : '',
									}}
								>
									{tag.name}
								</button>
							);
						})}
					</div>
				</div>
				<button
					className="w-full flex justify-center items-center  p-2.5 mt-4 text-white bg-brandOrange rounded-3xl"
					onClick={onCloseModal}
				>
					<span>Apply</span>
					<img
						src={checkedIcon}
						alt="checked icon"
						className="w-6 translate-y-[2px]"
					/>
				</button>
			</div>
		</PopUp>
	);
};
