export const enum USER_ERRORS_MESSAGES {
	NOT_FOUND = 'User not found',
	GET_ALL_USER_ERROR = 'Error getting all users',
	GET_USER_BY_ID_ERROR = 'Error getting user',
	CREATE_USER_ERROR = 'Oops! There was a problem creating user. Please try again later!',
	UPDATE_USER_ERROR = 'Oops! There was a problem updating user. Please try again later!',
	DELETE_USER_ERROR = 'Oops! There was a problem deleting user. Please try again later!',
	GET_USER_BY_EMAIL_ERROR = 'Error getting user by email',
	UPDATE_USER_SAVE_RECIPE_ERROR = 'Oops! There was a problem saving recipe. Please try again later!',
	DELETE_USER_SAVE_RECIPE_ERROR = 'Oops! There was a problem deliting save recipe. Please try again later!',
	UPDATE_USER_FAVOURITE_RECIPE_ERROR = 'Oops! There was a problem adding favourite recipe. Please try again later!',
	DELETE_USER_FAVOURITE_RECIPE_ERROR = 'Oops! There was a problem deleting favourite recipe. Please try again later!',
	UPDATE_USER_FOLLOW_CHEF_ERROR = 'Oops! There was a problem following chef. Please try again later!',
	DELETE_USER_FOLLOW_CHEF_ERROR = 'Oops! There was a problem unfollowing chef. Please try again later!',
	GET_PENDING_USERS_ERROR = 'Error getting pending users',
	UPDATE_USER_ACCEPT_CHEF_REQUEST_ERROR = 'Error handling user request to become a creator',
	UPDATE_USER_PREFERENCES = 'Oops! There was a problem updating preferences. Please try again later!',
	GET_USER_FAVOURITE_AND_SAVE_RECIPES_ERROR = 'Oops! There was a problem getting favourites and saves recipes. Please try again later!',
	GET_USER_LIKED_RECIPES_ERROR = 'Oops! There was a problem getting liked recipes. Please try again later!',
	GET_USER_SAVED_RECIPES_ERROR = 'Oops! There was a problem getting saved recipes. Please try again later!',
}
