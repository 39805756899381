export enum RecipeSort {
	LATEST = 'latest',
	OLDEST = 'oldest',
	LAST_DAY = 'last-day',
	LAST_WEEK = 'last-week',
	LAST_MONTH = 'last-month',
	MOST_LIKED = 'most-liked',
}

export enum ChefSort {
	MOST_POPULAR = 'most-popular',
	MOST_FOLLOWED = 'most-followed',
	MOST_RECIPES = 'most-recipes',
}

export interface IFiltersMenu {
	id: number;
	name: string;
	sort: RecipeSort;
}
