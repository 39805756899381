import { useQuery } from '@tanstack/react-query';

import { IPagination } from '@/interface/pagination.interface';
import { getRecipesByUserPreferences } from '@/service/api/recipe';

export const useGetRecipesByUserPreferences = (options: IPagination) => {
	const { take, skip } = options;
	const {
		data: userPreferenceRecipes,
		isLoading: isLoadingUserPreferenceRecipes,
		isError: isErrorUserPreferenceRecipes,
		error: errorUserPreferenceRecipes,
		refetch: refetchUserPreferenceRecipes,
	} = useQuery({
		queryKey: ['user-preference-recipes', take, skip],
		queryFn: () => getRecipesByUserPreferences(take, skip),
		retry: false,
	});

	return {
		userPreferenceRecipes: userPreferenceRecipes?.data ?? [],
		refetchUserPreferenceRecipes,
		totalEntities: userPreferenceRecipes?.total ?? 0,
		isLoadingUserPreferenceRecipes,
		isErrorUserPreferenceRecipes,
		errorUserPreferenceRecipes,
	};
};
