import { useMutation } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { createMealPlan } from '@/service/api/mealPlan';

const useCreateMealPlan = () => {
	const createMealPlanMutation = useMutation({
		mutationFn: createMealPlan,
		onSuccess: () => {
			notifySuccess('Meal plan created successfully');
		},
		onError: (error: Error) => {
			notifyError(`${error.message}`);
		},
	});

	return createMealPlanMutation;
};

export default useCreateMealPlan;
