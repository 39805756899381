import { EventInput } from '@fullcalendar/core/index.js';
import { useFormik } from 'formik';
import { useState } from 'react';

import { generateUniqueEventId } from './calendarFunctions';

import goBackArrowIcon from '@/assets/svg/go-back-arrow.svg';
import plusIcon from '@/assets/svg/plus-icon.svg';
import { Loader } from '@/components/common/Loader';
import { PopUp } from '@/components/common/PopUp';
import { SavedRecipePopUp } from '@/components/saved-recipes/SavedRecipesPopUp';
import { createMealPlanValidationSchema } from '@/helper/validations/meal-plan.validationts';
import useCreateMealPlan from '@/hooks/meal-plan/useCreateMealPlan';
import { MealPlanDto, RecipeMealType } from '@/interface/mealPlan.interfaces';

interface CreateMealPlanFormProps {
	selectedEvent: EventInput;
	setEvents: React.Dispatch<React.SetStateAction<EventInput[]>>;
}

export const CreateMealPlanForm = ({
	selectedEvent,
	setEvents,
}: CreateMealPlanFormProps) => {
	const [step, setStep] = useState(1);
	const [openModal, setOpenModal] = useState(false);
	const [recipesSelected, setRecipesSelected] = useState<number[]>([]);
	const handleModalState = () => setOpenModal(!openModal);
	const { mutate: createMealPlan, isPending } = useCreateMealPlan();

	const MEAL_PLAN_INITIAL_STATE: MealPlanDto = {
		recipes: recipesSelected,
		mealType: RecipeMealType.BREAKFAST as RecipeMealType,
		date: selectedEvent.event.start,
	};

	const formik = useFormik({
		initialValues: MEAL_PLAN_INITIAL_STATE,
		validationSchema: createMealPlanValidationSchema,
		onSubmit: (values) => {
			if (recipesSelected.length === 0) {
				throw Error('No recipe selected');
			}

			const mealPlanValues = {
				recipes: recipesSelected,
				mealType: values.mealType as RecipeMealType,
				date: selectedEvent.event.start,
			};

			createMealPlan(mealPlanValues, {
				onSuccess: (mealPlan) => {
					const firstRecipe = mealPlan.recipes[0];

					const newEvent = {
						id: generateUniqueEventId(),
						title: mealPlan.mealType.toUpperCase(),
						mealPlanId: mealPlan.id,
						recipe: {
							id: firstRecipe.id,
							title: firstRecipe.title,
							servings: parseInt(firstRecipe.servings?.toString() ?? '0', 10),
						},
						recipes: mealPlan.recipes,
						start: mealPlan.date,
					};

					setEvents((prevEvents) =>
						prevEvents.map((event) =>
							event.id === selectedEvent.event.id ? newEvent : event,
						),
					);
					handleModalState();
				},
			});
		},
	});

	return (
		<div>
			<button
				className="w-[250px] sm:w-[290px] h-[200px] xl:w-[410px] 2xl:w-[360px] p-4 text-black text-base rounded-lg shadow-lg border-dashed border-2 border-gray-300 flex items-center justify-center"
				onClick={() => handleModalState()}
				data-cy="create-meal-plan"
			>
				<span className="pr-2">Add a Recipe</span>
				<img src={plusIcon} alt="plus" />
			</button>
			<div>
				<PopUp open={openModal} onClose={handleModalState}>
					<div className="w-full h-full flex justify-center items-center p-4">
						<div className="bg-white p-10 rounded-3xl w-full max-w-2xl">
							<div className="h-full w-full">
								<form
									onSubmit={formik.handleSubmit}
									className="bg-white rounded-3xl"
								>
									<label
										htmlFor="mealType"
										className="text-sm md:text-base mb-[5px] tracking-wide block text-center"
									>
										{MEAL_PLAN_INITIAL_STATE.date.toDateString()}
									</label>
									{step === 1 && (
										<div className="grid grid-cols-1 gap-y-8">
											<div className="w-full sm:w-96 mx-auto">
												<label
													htmlFor="mealType"
													className="text-sm md:text-xl tracking-wide block text-center"
												>
													Select a Meal Type
												</label>
												<select
													name="mealType"
													onChange={formik.handleChange}
													value={formik.values.mealType}
													className="w-full text-slate-700 rounded-md border-none outline-none px-1 py-1.5 mt-2 shadow-sm focus:ring-1 focus:ring-inset focus:ring-slate-400 sm:text-sm sm:leading-6"
													data-cy="create-meal-plan-select-meal-type"
												>
													{Object.values(RecipeMealType).map((type, index) => (
														<option key={index} value={type}>
															{type}
														</option>
													))}
												</select>
												{formik.errors.mealType && (
													<p className="text-red-500 text-sm text-center">
														{formik.errors.mealType}
													</p>
												)}
											</div>
											<div className="flex justify-center items-center space-x-4 mt-4">
												<button
													type="button"
													onClick={() => setStep(2)}
													className="w-24 px-1 rounded-md bg-slate-100 py-2 font-semibold shadow-sm transition-colors hover:bg-brandGreen hover:text-white disabled:cursor-not-allowed"
													disabled={
														!formik.values.mealType || !!formik.errors.mealType
													}
												>
													Continue
												</button>
											</div>
										</div>
									)}
									{step === 2 && (
										<div className="grid grid-cols-1 gap-x-6 gap-y-8">
											<div className="absolute top-4 left-3">
												<button
													onClick={() => setStep(1)}
													className="bg-brandOrange rounded-full h-10 w-10 flex items-center justify-center"
												>
													<img
														src={goBackArrowIcon}
														alt="arrow-left"
														className="w-5 h-5"
													/>
												</button>
											</div>
											<div className="w-full text-center">
												<label
													htmlFor="savedRecipes"
													className="text-xl tracking-wide block"
												>
													Select one or more recipes from your saved recipes
												</label>
												<SavedRecipePopUp
													setRecipesSelected={setRecipesSelected}
												/>
												{formik.errors.recipes ||
													(recipesSelected.length === 0 && (
														<p className="text-red-500 text-sm text-center">
															{formik.errors.recipes}
														</p>
													))}
											</div>
											<div className="flex justify-center items-center space-x-4 mt-4">
												{isPending ? (
													<Loader size={4} />
												) : (
													<>
														<button
															data-cy="create-meal-plan-submit"
															type="submit"
															className="w-64 tracking-wide block px-1 rounded-md bg-slate-100 py-2 font-semibold shadow-sm transition-colors hover:bg-brandGreen hover:text-white disabled:cursor-not-allowed"
															disabled={
																formik.isSubmitting ||
																!formik.isValid ||
																recipesSelected.length === 0 ||
																!!formik.errors.recipes
															}
														>
															Create meal plan
														</button>
													</>
												)}
											</div>
										</div>
									)}
								</form>
							</div>
						</div>
					</div>
				</PopUp>
			</div>
		</div>
	);
};
