import { useState } from 'react';
import { useSearchBox } from 'react-instantsearch';

import { searchBarTags } from '@/assets/mock/tags_menu_items.mock';

export const AlgoliaSearchBar = () => {
	const [searchBarTagsState, setSearchBarTagsState] = useState(searchBarTags);
	const { refine, clear } = useSearchBox();

	const onSetSearchBarTag = (param: string, id: number) => {
		setSearchBarTagsState((prevTags) =>
			prevTags.map((tag) =>
				tag.id === id
					? { ...tag, isActive: true }
					: { ...tag, isActive: false },
			),
		);
		refine(param);
	};

	const onClearSearchBarTags = () => {
		setSearchBarTagsState((prevTags) =>
			prevTags.map((tag) => ({ ...tag, isActive: false })),
		);
		clear();
	};

	return (
		<div
			data-cy="tag-search-bar"
			className="flex flex-wrap gap-2 justify-center md:justify-start"
		>
			{searchBarTagsState.map((tag) => (
				<button
					data-cy="algolia-bar-sort-item"
					key={tag.id}
					className="block grow sm:grow-0 px-4 py-2.5 text-sm text-slate-700 bg-slate-100 rounded-3xl border-[1px] border-slate-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10"
					onClick={() => onSetSearchBarTag(tag.param, tag.id)}
					style={{
						backgroundColor: tag.isActive ? 'rgb(232 201 136 / 0.1)' : '',
						borderColor: tag.isActive ? 'rgb(232 201 136)' : '',
						color: tag.isActive ? 'rgb(232 201 136)' : '',
					}}
				>
					{tag.name}
				</button>
			))}
			<button
				className="block grow sm:grow-0 px-4 py-2.5 text-sm text-green-700 bg-green-100 rounded-3xl border-[1px] border-green-300 hover:text-brandOrange hover:border-brandOrange hover:bg-brandOrange/10"
				onClick={onClearSearchBarTags}
			>
				Clear all
			</button>
		</div>
	);
};
