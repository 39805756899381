import { useState } from 'react';

import { CreateCollectionModal } from '@/pages/user/collections/CreateCollectionModal';

interface TabProfileNavProps {
	savedRecipesCount: number;
	createdRecipesCount: number;
	favouritesRecipesCount: number;
	collectionsCount: number;
	activeTab: string;
	setActiveTab: (tab: string) => void;
	isChef: boolean;
	resetSkip: () => void;
	onCreateCollection?: (title: string) => void;
}

export const TabProfileNav = ({
	savedRecipesCount,
	createdRecipesCount,
	favouritesRecipesCount,
	collectionsCount,
	activeTab,
	setActiveTab,
	isChef,
	resetSkip,
	onCreateCollection,
}: TabProfileNavProps) => {
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleCreateCollection = (title: string) => {
		if (onCreateCollection) onCreateCollection(title);
		setIsModalOpen(false);
	};

	const handleTabClick = (tab: string) => {
		setActiveTab(tab);
	};

	return (
		<div>
			<nav className="flex justify-center sm:justify-between flex-col sm:flex-row gap-4 sm:gap-10 mb-10">
				<div className="flex justify-center flex-wrap text-center sm:text-left gap-4 sm:gap-6 md:gap-8">
					<div
						data-cy="user-saved-recipes"
						className={`cursor-pointer ${
							activeTab === 'saved' ? 'text-brandOrange' : 'text-gray-400'
						} w-auto h-[32px] flex flex-col items-center sm:items-start`}
						onClick={() => {
							resetSkip();
							setActiveTab('saved');
						}}
					>
						<p className="text-[11px] md:text-[13px]">
							{savedRecipesCount} Saved
						</p>
						<p className="text-[19px] md:text-[28px] font-semibold tracking-tighter">
							Saved Recipes
						</p>
					</div>
					<div
						data-cy="user-favourites-recipes"
						className={`cursor-pointer ${
							activeTab === 'favourites' ? 'text-brandOrange' : 'text-gray-400'
						} w-auto h-[32px] flex flex-col items-center sm:items-start`}
						onClick={() => {
							resetSkip();
							setActiveTab('favourites');
						}}
					>
						<p className="text-[11px] md:text-[13px]">
							{favouritesRecipesCount} Favourites
						</p>
						<p className="text-[19px] md:text-[28px] font-semibold tracking-tighter">
							Favourite Recipes
						</p>
					</div>
					<div
						data-cy="user-collections"
						className={`cursor-pointer ${
							activeTab === 'collections' ? 'text-brandOrange' : 'text-gray-400'
						} w-auto h-[32px] flex flex-col items-center sm:items-start`}
						onClick={() => {
							resetSkip();
							handleTabClick('collections');
						}}
					>
						<p className="text-[11px] md:text-[13px]">
							{collectionsCount} Collections
						</p>
						<p className="text-[19px] md:text-[28px] font-semibold tracking-tighter">
							Collections
						</p>
					</div>
					{isChef && (
						<div
							data-cy="user-created-recipes"
							className={`cursor-pointer ${
								activeTab === 'created' ? 'text-brandOrange' : 'text-gray-400'
							} w-auto h-[32px] flex flex-col items-center sm:items-start`}
							onClick={() => setActiveTab('created')}
						>
							<p className="text-[11px] md:text-[13px]">
								{createdRecipesCount} Recipes
							</p>
							<p className="text-[19px] md:text-[28px] font-semibold tracking-tighter">
								Created Recipes
							</p>
						</div>
					)}
				</div>
				<button
					className="order-last grow min-[412px]:order-none min-[412px]:grow-0 flex items-center justify-center h-12 gap-x-2 py-4 px-4 bg-brandOrange rounded-3xl text-white transition-colors hover:bg-[#E1B864]"
					onClick={() => setIsModalOpen(true)}
				>
					Create Collection
				</button>
			</nav>

			<CreateCollectionModal
				open={isModalOpen}
				onClose={() => setIsModalOpen(false)}
				onCreate={handleCreateCollection}
			/>
		</div>
	);
};
