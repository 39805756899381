import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './app.css';
import './index.css';

import routerLandingPage from '@/configs/landing-page';
import { AuthProvider } from '@/context/AuthProvider';

import router from '@configs/router';

const enableSentry =
	import.meta.env.MODE === 'production' || import.meta.env.MODE === 'staging';

if (enableSentry) {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_URI,
		integrations: [
			Sentry.browserTracingIntegration(),
			Sentry.replayIntegration(),
		],

		tracesSampleRate: +(import.meta.env.VITE_SENTRY_TRACE_RATE ?? 0.1),
		environment: import.meta.env.MODE,

		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
	});
}

const queryClient = new QueryClient();

const selectedRouter =
	import.meta.env.VITE_EARLY_ACCESS_MODE === 'true'
		? routerLandingPage
		: router;

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<AuthProvider>
		<QueryClientProvider client={queryClient}>
			<RouterProvider router={selectedRouter} />
			<ToastContainer />
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</AuthProvider>,
);
