import { useMutation, useQueryClient } from '@tanstack/react-query';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { MealPlanDto } from '@/interface/mealPlan.interfaces';
import { editMealPlan } from '@/service/api/mealPlan';

export const useUpdateMealPlan = () => {
	const queryClient = useQueryClient();

	const updateMealPlanMutation = useMutation({
		mutationFn: (data: { id: number; updateMealPlan: MealPlanDto }) =>
			editMealPlan(data.id, data.updateMealPlan),

		onSuccess: (data, variables) => {
			notifySuccess('Meal Plan updated successfully');

			queryClient.setQueryData(['meal-plan-id', variables.id], data);
		},

		onError: (error: Error) => {
			notifyError(`${error.message}`);
		},
	});

	return { updateMealPlanMutation };
};
