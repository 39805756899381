import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import hidePasswordImg from '@/assets/svg/hide-password.svg';
import showPasswordImg from '@/assets/svg/show-password.svg';
import { Loader } from '@/components/common/Loader';
import { SocialIcons } from '@/components/sign-up/social-icons/SocialIcons';
import {
	REGISTER_COMMON_ERROR,
	REGISTER_SUCCESSFUL,
} from '@/error/authentication.notify.messages';
import { notifyError, notifySuccess } from '@/helper/notifications';
import { signupValidationSchema } from '@/helper/validations/signup.validations';
import { TermsAndConditionsModal } from '@/pages/sign-up/TAC/modal/TermsAndConditionsModal';
import { createUser } from '@/service/api/user';

import kitchedLogo from '@assets/svg/kitched-logo-login.svg';

export const FormSignUp = () => {
	const navigate = useNavigate();
	const validationSchema = signupValidationSchema();
	const [openTermsModal, setOpenTermsModal] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);

	const onOpenTermsModal = () => setOpenTermsModal(true);
	const onCloseTermsModal = () => setOpenTermsModal(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			last_name: '',
			email: '',
			password: '',
			confirmPassword: '',
			agreeTerms: false,
		},
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			try {
				await createUser(values);

				notifySuccess(REGISTER_SUCCESSFUL);
				navigate('/login');
			} catch (error: unknown) {
				let message = REGISTER_COMMON_ERROR;
				if (error instanceof AxiosError) {
					const errorMessageFromBackend = error.response?.data.message;
					if (Array.isArray(errorMessageFromBackend)) {
						message = errorMessageFromBackend.join(', ');
					} else {
						message = errorMessageFromBackend;
					}
				}

				notifyError(message);
			} finally {
				setSubmitting(false);
			}
		},
	});

	return (
		<div className="w-[320px] mt-20 mx-[30px] flex flex-col">
			<div className="w-[271px] h-[92px] text-5xl text-center mx-auto ">
				<h1 className="text-[52px] w-full font-semibold -mt-9 flex flex-col items-center">
					<p>Sign Up to</p>
					<img
						src={kitchedLogo}
						alt="logo"
						className="mt-3 w-[190px] h-[45px]"
					/>
				</h1>
			</div>

			<SocialIcons />

			<form
				className="flex flex-col gap-[42px] w-[320px] h-[420px] max-w-lg mx-auto md:max-w-md lg:max-w-lg xl:max-w-xl mt-2"
				onSubmit={formik.handleSubmit}
			>
				<div className="flex flex-col gap-[16px] w-[320px]">
					<div className="relative">
						<input
							type="text"
							name="name"
							placeholder="Enter your first name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.name}
							className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandOrange focus:bg-white bg-slate-50"
						/>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="name">NAME</label>
						</div>

						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.name && formik.errors.name && (
								<div>{formik.errors.name}</div>
							)}
						</div>
					</div>

					<div className="relative">
						<input
							type="text"
							name="last_name"
							placeholder="Enter your last name"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.last_name}
							className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandOrange focus:bg-white bg-slate-50"
						/>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="last_name">LAST NAME</label>
						</div>

						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.last_name && formik.errors.last_name && (
								<div>{formik.errors.last_name}</div>
							)}
						</div>
					</div>

					<div className="relative">
						<input
							type="email"
							name="email"
							placeholder="Enter your email"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.email}
							className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandOrange focus:bg-white bg-slate-50"
						/>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="email">EMAIL</label>
						</div>

						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.email && formik.errors.email && (
								<div>{formik.errors.email}</div>
							)}
						</div>
					</div>
					<div className="relative">
						<div className="flex">
							<input
								type={showPassword ? 'text' : 'password'}
								name="password"
								placeholder="Enter your password"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.password}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandOrange focus:bg-white bg-slate-50 pr-10"
								data-cy="signup-password-input"
							/>
							<button
								type="button"
								onClick={() => setShowPassword(!showPassword)}
								className="absolute right-3 top-1/2 transform -translate-y-1/2"
							>
								<img
									src={showPassword ? showPasswordImg : hidePasswordImg}
									alt="password-icon"
									className="w-[20px] h-[20px]"
								/>
							</button>
						</div>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="password">PASSWORD</label>
						</div>
						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.password && formik.errors.password && (
								<div data-cy="signup-password-error">
									{formik.errors.password}
								</div>
							)}
						</div>
					</div>

					<div className="relative">
						<div className="flex">
							<input
								type={showConfirmPassword ? 'text' : 'password'}
								name="confirmPassword"
								placeholder="Confirm your password"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.confirmPassword}
								className="w-full h-[53px] rounded-full px-6 pt-2 focus:outline-none text-[13px] font-semibold focus:ring-1 focus:ring-brandOrange focus:bg-white bg-slate-50 pr-10"
							/>
							<button
								type="button"
								onClick={() => setShowConfirmPassword(!showConfirmPassword)}
								className="absolute right-3 top-1/2 transform -translate-y-1/2"
							>
								<img
									src={showConfirmPassword ? showPasswordImg : hidePasswordImg}
									alt="password-icon"
									className="w-[20px] h-[20px]"
								/>
							</button>
						</div>
						<div className="absolute left-6 top-2.5 text-[9px] text-loginTextGrey">
							<label htmlFor="confirmPassword">CONFIRM YOUR PASSWORD</label>
						</div>
						<div className="text-alertRed pl-6 text-[9px]">
							{formik.touched.confirmPassword &&
								formik.errors.confirmPassword && (
									<div>{formik.errors.confirmPassword}</div>
								)}
						</div>
					</div>

					<div className="relative">
						<div className="flex items-center gap-2">
							<input
								type="checkbox"
								name="agreeTerms"
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								checked={formik.values.agreeTerms}
								className="w-5 h-4 rounded-sm accent-brandOrange"
							/>
							<label
								htmlFor="agreeTerms"
								className="text-[13px] -mt-[3px] text-gray-90 cursor-pointer hover:underline"
								onClick={onOpenTermsModal}
							>
								I agree to the Terms & Conditions
							</label>
						</div>
						{formik.touched.agreeTerms && formik.errors.agreeTerms && (
							<div className="text-alertRed pl-7 text-[9px]  -mt-1">
								{formik.errors.agreeTerms}
							</div>
						)}
					</div>

					<button
						data-cy="sign-up-submit"
						type="submit"
						className="w-[156px] h-[52px] rounded-full bg-brandOrange text-white flex justify-center items-center mx-auto  transition-all hover:text-brandOrange hover:bg-white hover:ring-2 hover:ring-brandOrange hover:cursor-pointer"
						disabled={formik.isSubmitting}
					>
						{formik.isSubmitting ? (
							<Loader size={1.4} />
						) : (
							<span className="text-[15px] font-semibold">Sign Up</span>
						)}
						<span className="p-[4px] bg-brandOrange rounded-full border-2 border-white absolute ml-36">
							<img
								src={arrowUpRight}
								alt="arrow-icon"
								style={{ width: '24px' }}
							/>
						</span>
					</button>
				</div>
			</form>

			<TermsAndConditionsModal
				open={openTermsModal}
				onClose={onCloseTermsModal}
			/>
		</div>
	);
};
