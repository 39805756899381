import { Link } from 'react-router-dom';

import favGrayIcon from '@/assets/svg/favorite-recipe-gray-icon.svg';
import favRedIcon from '@/assets/svg/favorite-recipe-red-icon.svg';
import saveRecipeBlackIcon from '@/assets/svg/save-recipe-black-icon.svg';
import saveRecipeIcon from '@/assets/svg/save-recipe-color-icon.svg';
import userBlackIcon from '@/assets/svg/user-black.svg';
import { CdnImage } from '@/components/images/CdnImage';
import { useManageFavouriteRecipe } from '@/hooks/user-favourite/useManageFavouriteRecipe';
import { useManageSavedRecipe } from '@/hooks/user-saved/useManageSavedRecipe';
import { IRecipe } from '@/interface/recipe.interfaces';
import { logRecipeLike } from '@/service/analytics/analytics';

export interface CardInfoProps {
	recipe: IRecipe;
	isChefProfile?: boolean;
}

export const MasonryCardInfo = ({ recipe, isChefProfile }: CardInfoProps) => {
	const { isSaved, addSavedRecipe, deleteSavedRecipe } =
		useManageSavedRecipe(recipe);
	const { isFavorite, addFavouriteRecipe, deleteFavouriteRecipe } =
		useManageFavouriteRecipe(recipe);

	const { title, chef, uuid } = recipe;

	const handleSave = () => {
		if (isSaved) {
			deleteSavedRecipe();
		} else {
			addSavedRecipe();
		}
	};

	const handleFavorite = () => {
		if (!isFavorite) {
			const chefFullName = `${chef?.user?.name || 'Unknown'} ${
				chef?.user?.last_name || 'Unknown'
			}`;
			const chefId = chef?.uuid;
			logRecipeLike(uuid, title, chefId, chefFullName);
			addFavouriteRecipe();
		} else {
			deleteFavouriteRecipe();
		}
	};

	return (
		<div className="w-full mt-2 mb-4 ">
			<Link data-cy="recipe-dinamic-card-link" to={`/recipe/detail/${uuid}`}>
				<p className="text-lg min-[420px]:text-xl mx-2.5 font-bold tracking-tight truncate hover:text-brandRed">
					{title}
				</p>
			</Link>

			<div className="w-full flex items-center px-2.5 gap-2.5 font-light text-grayText">
				{!isChefProfile && (
					<div className="flex items-center gap-2 w-2/3 text-sm font-light tracking-normal text-gray-600">
						<CdnImage
							height={20}
							width={20}
							src={chef?.user?.profile_image?.src || userBlackIcon}
							alt="avatar"
							className="object-cover rounded-full"
						/>
						<p className="text-xs truncate font-helveticaNeue tracking-normal">{`${chef?.user?.name} ${chef?.user?.last_name}`}</p>
					</div>
				)}

				<div className="flex-1"></div>
				<img
					src={isFavorite ? favRedIcon : favGrayIcon}
					alt="favorite-icon"
					className="w-4 h-4 hover:scale-110 hover:cursor-pointer transform"
					data-cy="like-icon"
					onClick={handleFavorite}
				/>
				<img
					src={isSaved ? saveRecipeIcon : saveRecipeBlackIcon}
					alt="saved-icon"
					className="w-4 h-4 hover:scale-110 hover:cursor-pointer transform"
					data-cy="save-icon"
					onClick={handleSave}
				/>
			</div>
		</div>
	);
};
