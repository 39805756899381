import { AxiosError } from 'axios';

import { handleAxiosError } from '@/components/common/errorMessageForAxios/errorMessageForAxios';
import { RECIPE_ERRORS_MESSAGES } from '@/error/recipe-errors.enum';
import { IGetAllResponse } from '@/interface/get-all-response';
import { IIngredientObj } from '@/interface/ingredient.interfaces';
import { IPagination } from '@/interface/pagination.interface';
import {
	EditRecipeDto,
	INewRecipeDto,
	IRecipe,
} from '@/interface/recipe.interfaces';
import Api from '@/service';

const RESOURCE = 'recipe';

export enum OrderByDate {
	ASC = 'ASC',
	DESC = 'DESC',
}

interface UpdateRecipeArgs {
	uuid: string;
	newRecipe: EditRecipeDto;
}

export const createRecipe = async (
	newRecipe: INewRecipeDto,
): Promise<IRecipe> => {
	try {
		const { data } = await Api.post(`/${RESOURCE}`, newRecipe);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			RECIPE_ERRORS_MESSAGES.CREATE_RECIPE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const ingredientsCheck = async (
	url: string,
): Promise<IIngredientObj> => {
	try {
		const body = {
			url: url,
		};

		const config = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
			},
		};

		const { data } = await Api.post(
			`/${RESOURCE}/ingredients-check`,
			body,
			config,
		);

		return data;
	} catch (error: unknown) {
		throw new Error(RECIPE_ERRORS_MESSAGES.INGREDIENTS_CHECK_ERROR);
	}
};

export const getAllRandomRecipes = async (
	take: number,
	skip: number,
): Promise<IGetAllResponse<IRecipe>> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/random`, {
			params: { take: take, skip: skip },
		});

		return data;
	} catch (error: unknown) {
		console.log({ error });
		throw new Error(RECIPE_ERRORS_MESSAGES.GET_ALL_RECIPE_ERROR);
	}
};

export const getAllRecipesByDate = async (
	order: OrderByDate,
	options: IPagination,
): Promise<IGetAllResponse<IRecipe>> => {
	const { tags = '' } = options;
	try {
		const { data } = await Api.get(
			`/${RESOURCE}/all-by-date/${order}?${tags}`,
			{
				params: {
					take: options.take,
					skip: options.skip,
					sort: options.sort,
				},
			},
		);
		return data;
	} catch (error: unknown) {
		console.log({ error });
		throw new Error(RECIPE_ERRORS_MESSAGES.GET_ALL_RECIPE_ERROR);
	}
};

export const getMostLikedRecipes = async (
	options: IPagination,
): Promise<IGetAllResponse<IRecipe>> => {
	const { tags = '' } = options;
	try {
		const { data } = await Api.get(`/${RESOURCE}/most-liked?${tags}`, {
			params: {
				take: options.take,
				skip: options.skip,
				sort: options.sort,
			},
		});

		return data;
	} catch (error: unknown) {
		throw new Error(RECIPE_ERRORS_MESSAGES.GET_RECIPE_BY_ID_ERROR);
	}
};

export const getChefRecipes = async (
	chefUuid: string,
	options: IPagination,
): Promise<IGetAllResponse<IRecipe>> => {
	const { tags = '' } = options;
	try {
		const { data } = await Api.get(
			`/${RESOURCE}/chef-recipes/${chefUuid}?${tags}`,
			{
				params: {
					take: options.take,
					skip: options.skip,
					sort: options.sort,
				},
			},
		);

		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(RECIPE_ERRORS_MESSAGES.GET_RECIPE_BY_ID_ERROR);
	}
};

export const getLatestRecipe = async (): Promise<IRecipe> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/latest/${OrderByDate.DESC}`);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError) {
			throw new Error(error.message);
		}
		throw new Error(RECIPE_ERRORS_MESSAGES.GET_RECIPE_BY_ID_ERROR);
	}
};

export const getRecipeById = async (id: string): Promise<IRecipe> => {
	try {
		const { data } = await Api.get(`/${RESOURCE}/${id}`);
		return data;
	} catch (error: unknown) {
		if (error instanceof AxiosError && error.response?.status === 404) {
			throw new Error(RECIPE_ERRORS_MESSAGES.NOT_FOUND);
		}
		throw new Error(RECIPE_ERRORS_MESSAGES.GET_RECIPE_BY_ID_ERROR);
	}
};

export const getRecipesByUserPreferences = async (
	take: number,
	skip: number,
): Promise<IGetAllResponse<IRecipe>> => {
	try {
		const { data } = await Api.get<IGetAllResponse<IRecipe>>(
			`/${RESOURCE}/user/preferences`,
			{
				params: { take, skip },
			},
		);
		return data;
	} catch (error: unknown) {
		throw new Error(RECIPE_ERRORS_MESSAGES.GET_ALL_RECIPE_ERROR);
	}
};

export const updateRecipe = async ({
	uuid,
	newRecipe,
}: UpdateRecipeArgs): Promise<IRecipe> => {
	try {
		const { data } = await Api.patch(`/${RESOURCE}/${uuid}`, newRecipe);
		return data;
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			RECIPE_ERRORS_MESSAGES.UPDATE_RECIPE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};

export const deleteRecipe = async (uuid: string): Promise<void> => {
	try {
		await Api.delete(`/${RESOURCE}/${uuid}`);
	} catch (error: unknown) {
		const errorMessage = handleAxiosError(
			error,
			RECIPE_ERRORS_MESSAGES.DELETE_RECIPE_ERROR,
		);
		return Promise.reject(new Error(errorMessage));
	}
};
