import userNoImage from '@/assets/image/user-no-image.jpg';
import { CdnImage } from '@/components/images/CdnImage';
import { Chef } from '@/context/interfaces';

interface ChefSmallCardInfoProps {
	chef: Chef;
}

export const ChefSmallCardInfo = ({ chef }: ChefSmallCardInfoProps) => {
	const profileImage = chef.user?.profile_image?.src
		? chef.user?.profile_image?.src
		: userNoImage;

	const thereIsNoProfileImage = profileImage === userNoImage;

	return (
		<div className="flex flex-col w-full 2xl:w-[235px]">
			<CdnImage
				src={profileImage}
				alt="chef-avatar"
				className={`w-full h-[200px] min-[500px]:h-[250px] object-cover rounded-3xl cursor-pointer  ${
					thereIsNoProfileImage && 'ring-1 ring-gray-300'
				}`}
			/>

			<div className="flex items-center gap-2 mt-1.5 text-xs px-[5px] font-light tracking-normal text-gray-600">
				<p className="truncate text-[15px] tracking-[0.02em] font-helveticaNeue">{`${chef?.user?.name} ${chef?.user?.last_name}`}</p>
			</div>
		</div>
	);
};
