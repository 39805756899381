import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import chevronDownBlackIcon from '@/assets/svg/chevron-down-black.svg';

interface DropdownMenuProps<T> {
	options: T[];
	selectedValues: T[];
	label: string;
	onChange: (value: T) => void;
	renderItem: (item: T) => React.ReactNode;
}

function classNames(...classes: string[]) {
	return classes.filter(Boolean).join(' ');
}

function DropdownMenu<T>({
	options,
	selectedValues,
	label,
	onChange,
	renderItem,
}: DropdownMenuProps<T>) {
	const handleItemClick = (item: T) => {
		onChange(item);
	};

	return (
		<Menu as="div" className="relative inline-block text-left w-full">
			<div className="flex flex-col items-center w-full">
				<div className="relative w-2/3 lg:w-3/4 2xl:w-2/3 flex justify-center">
					<div className="absolute left-5 md:left-9 lg:left-5 top-2.5 text-[9px] text-loginTextGrey">
						<label htmlFor="cooking-level">{label}</label>
					</div>
					<Menu.Button
						data-cy={`button-${label.toLowerCase().replace(' ', '-')}-menu`}
						className="inline-flex justify-between items-center gap-x-1.5 rounded-full pl-1 pr-3 py-[4px] text-sm font-semibold shadow-sm hover:bg-gray-300 text-black bg-slate-50 border-none ring-none h-[53px] w-[410px] sm:w-[410px] md:w-11/12 lg:w-[380px] 2xl:w-full px-6 pt-2"
					>
						<span className="pl-3.5 pt-2 lg:pt-3.5">
							{selectedValues.length > 0
								? renderItem(selectedValues[0])
								: `Select your ${label.toLowerCase()}`}
						</span>
						<img src={chevronDownBlackIcon} className="mt-1" />
					</Menu.Button>
				</div>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-100"
					enterFrom="transform opacity-0 scale-95"
					enterTo="transform opacity-100 scale-100"
					leave="transition ease-in duration-75"
					leaveFrom="transform opacity-100 scale-100"
					leaveTo="transform opacity-0 scale-95"
				>
					<Menu.Items className="right-0 z-10 mt-2 w-56 overflow-y-auto max-h-52 origin-top-right rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-black bg-slate-100 border-none">
						<div className="py-1">
							{options?.map((option, index) => (
								<Menu.Item key={index}>
									{({ active }) => (
										<div
											data-cy={`update-${label
												.toLowerCase()
												.replace(' ', '-')}-${String(option)
												.toLowerCase()
												.replace(' ', '-')}`}
											className={classNames(
												active ? 'bg-gray-300/50' : '',
												'flex items-center pl-2 cursor-pointer',
												selectedValues.includes(option)
													? 'opacity-50 cursor-not-allowed'
													: '',
											)}
											onClick={() => handleItemClick(option)}
										>
											{renderItem(option)}
										</div>
									)}
								</Menu.Item>
							))}
						</div>
					</Menu.Items>
				</Transition>
			</div>
		</Menu>
	);
}

export default DropdownMenu;
