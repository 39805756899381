import arrowLeftIcon from '@/assets/svg/arrowleft-icon.svg';
import arrowRightIcon from '@/assets/svg/arrowright-icon.svg';

interface NavigationButtonsProps {
	currentPage: number;
	totalPages: number;
	handlePrevClick: () => void;
	handleNextClick: () => void;
}

export const NavigationButtons = ({
	currentPage,
	totalPages,
	handlePrevClick,
	handleNextClick,
}: NavigationButtonsProps) => {
	return (
		<section className="flex items-center justify-between gap-[6px] mx-auto mt-2 h-[52px] w-[110px]">
			<button
				data-cy="previous-page-button"
				onClick={handlePrevClick}
				className="w-[52px] h-[52px] bg-buttonGrey rounded-full flex items-center justify-center hover:bg-gray-100"
				disabled={currentPage === 0}
			>
				<img src={arrowLeftIcon} alt="arrow-icon" className="w-4 h-4" />
			</button>
			<button
				data-cy="next-page-button"
				onClick={handleNextClick}
				className="w-[52px] h-[52px] bg-brandOrange rounded-full flex items-center justify-center hover:bg-[#E1B864]"
				disabled={currentPage === totalPages - 1}
			>
				<img src={arrowRightIcon} alt="arrow-icon" className="w-4 h-4" />
			</button>
		</section>
	);
};
