export interface CreateUserDto {
	name: string;
	email: string;
}

export enum ICookingLevel {
	AMATEUR = 'Amateur',
	MEDIUM = 'Medium',
	EXPERT = 'Expert',
}

export enum FavoriteChefs {
	FAVOURITE_CHEF_1 = 'Yotam Ottolenghi',
	FAVOURITE_CHEF_2 = 'Stanley Tucci',
	FAVOURITE_CHEF_3 = 'Kylie Kwong',
	FAVOURITE_CHEF_4 = 'Jamie Oliver',
}

export enum FavoriteIngredients {
	FISH = 'Fish',
	TOFU = 'Tofu',
	CHICKEN = 'Chicken',
	MEAT = 'Meat',
}

export enum RecipePreferences {
	MEXICAN = 'Mexican',
	KOREAN = 'Korean',
	VEGAN = 'Vegan',
	ITALIAN = 'Italian',
}

export interface UserPreferences {
	recipePreferences?: RecipePreferences[];
	cookingLevel?: ICookingLevel;
	favoriteChefs?: FavoriteChefs[];
	favoriteIngredients?: FavoriteIngredients[];
}
