import * as Yup from 'yup';

export const signupValidationSchema = () => {
	return Yup.object({
		name: Yup.string()
			.matches(
				/^[A-Za-z]+(?: [A-Za-z]+)*$/,
				'First name must contain only letters and single spaces between words',
			)
			.test(
				'len',
				'First name must be at least 3 characters long without spaces',
				(val) => {
					if (val) {
						return val.replace(/\s+/g, '').length >= 3;
					}
					return false;
				},
			)
			.required('First name is required'),
		last_name: Yup.string()
			.matches(
				/^[A-Za-z]+(?: [A-Za-z]+)*$/,
				'First name must contain only letters and single spaces between words',
			)
			.test(
				'len',
				'First name must be at least 3 characters long without spaces',
				(val) => {
					if (val) {
						return val.replace(/\s+/g, '').length >= 3;
					}
					return false;
				},
			)
			.required('Last name is required'),
		email: Yup.string()
			.email('Invalid email address')
			.required('Email is required'),
		password: Yup.string()
			.required('Password is required')
			.min(8, 'Password must be at least 8 characters')
			.matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
			.matches(/[a-z]/, 'Password must contain at least one lowercase letter')
			.matches(/[0-9]/, 'Password must contain at least one number')
			.matches(
				/[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/,
				'Password must contain a special character or a space that is neither at the beginning nor at the end',
			)
			.matches(/^(?!\s).*$/, 'Password must not start with a space')
			.matches(/^(?!.*\s$).*$/, 'Password must not end with a space'),
		confirmPassword: Yup.string()
			.oneOf([Yup.ref('password')], 'Passwords must match')
			.required('Confirm password is required'),
		agreeTerms: Yup.boolean().oneOf(
			[true],
			'You must agree to the terms and conditions',
		),
	});
};
