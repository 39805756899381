import { useEffect, useState } from 'react';

import { TagsSearchBar } from '../common/recipe/TagsSearchBar';

import { trendingRecipesFiltersMenu } from '@/assets/mock/date_filter_items.mock';
import { Loader } from '@/components/common/Loader';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { TagsSearchMenuPopUp } from '@/components/common/pop-up/TagsPopUp';
import { RecipesNav } from '@/components/common/recipe/RecipesNav';
import { TrendingGridComponent } from '@/components/common/recipe/masonry-recipe/TrendingGridComponent';
import { useGetMostLikedRecipes } from '@/hooks/recipes/useGetMostLikedRecipes';
import { usePagination } from '@/hooks/usePagination';
import { RecipeSort } from '@/interface/filter.interface';
import { IRecipe } from '@/interface/recipe.interfaces';

export const TrendingOnKitchedSection = () => {
	const [open, setOpen] = useState(false);
	const onCloseModal = () => setOpen(false);
	const [tags, setTags] = useState<string>('');
	const [sort, setSort] = useState(RecipeSort.LATEST);
	const [skip, setSkip] = useState(0);
	const take = 24;

	const {
		mostLikedRecipes,
		errorMostLikedRecipes,
		isLoadingMostLikedRecipes,
		refetchMostLikedRecipes,
		totalEntities,
	} = useGetMostLikedRecipes({ take, skip, sort, tags });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities,
			setSkip,
		});

	const onHandleSearchTag = (tag: string) => {
		setTags(tag);
	};

	useEffect(() => {
		refetchMostLikedRecipes();
	}, [skip, sort, tags]);

	if (isLoadingMostLikedRecipes)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (errorMostLikedRecipes) return <></>;

	return (
		<div
			data-cy="trendingonkitched-section"
			className="w-full flex flex-col items-center justify-center pt-5"
			id="trending-recipes"
		>
			<div className="w-full flex flex-col gap-y-3 px-5 mb-8">
				<RecipesNav
					textTitle="Trending"
					titleWithColor="on Kitched"
					colorText="brandRed"
					filtersMenu={trendingRecipesFiltersMenu}
					setSort={setSort}
					setOpen={setOpen}
				/>
				<TagsSearchBar
					onHandleSearchTagByQuery={onHandleSearchTag}
					sectionName={'trending'}
				/>
			</div>

			<TrendingGridComponent recipes={mostLikedRecipes?.data as IRecipe[]} />

			<NavigationButtons
				currentPage={currentPage}
				totalPages={totalPages}
				handleNextClick={onHandleNext}
				handlePrevClick={onHandlePrevious}
			/>

			<TagsSearchMenuPopUp
				onHandleSearchTag={onHandleSearchTag}
				open={open}
				onCloseModal={onCloseModal}
			/>
		</div>
	);
};
