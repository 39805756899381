import { LandingRecipesSlider } from './recipes-slider/LandingRecipesSlider';

import { ComingSoon } from '@/pages/signup-landing/coming-soon/ComingSoon';
import { ComingSoonImages } from '@/pages/signup-landing/decorative-images/ComingSoonImages';

export const SignupLanding = () => {
	return (
		<div>
			<div
				className="bg-brandOrange min-h-screen flex items-center justify-center"
				data-cy="comingsoon-page"
			>
				<div className="flex flex-col md:flex-row items-center lg:justify-center md:gap-auto xl:gap-24">
					<ComingSoon />
					<div className="hidden md-custom:block">
						<ComingSoonImages />
					</div>
				</div>
			</div>
			<div className=" bg-brandOrange pt-5">
				<LandingRecipesSlider />
			</div>
		</div>
	);
};
