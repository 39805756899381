import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { notifyError, notifySuccess } from '@/helper/notifications';
import { ResendVerificationForm } from '@/pages/resend-verification/ResendVerificationForm';
import { resendConfirmationCode } from '@/service/cognito/cognito.services';

import kitchedLogo from '@assets/svg/kitched-logo-login.svg';

export const ResendVerification = () => {
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();

	const handleResendVerification = async (values: { email: string }) => {
		setIsLoading(true);
		try {
			await resendConfirmationCode(values.email);
			notifySuccess('Verification email sent to your email!');
			navigate('/login');
		} catch (event: unknown) {
			if (event instanceof Error) notifyError(event.message);
			else notifyError("UNEXPECTED ERROR: Couldn't send verification email");
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<div className="container mx-auto mt-12 flex flex-col items-center font-helveticaNeue">
			<img src={kitchedLogo} alt="logo" className="mt-5 w-[240px] h-[80px]" />
			<h1 className="text-center text-3xl font-bold mb-12">
				Resend verification code
			</h1>
			<div className="max-w-lg w-full">
				<ResendVerificationForm
					isLoading={isLoading}
					onSubmit={handleResendVerification}
				/>
			</div>
		</div>
	);
};
