import { decorativeImages } from '@/pages/signup-landing/decorative-images/decorativeImages';

export const ComingSoonImages = () => {
	return (
		<section className="md:w-[554px] h-[600px] justify-center items-center md:flex relative">
			{decorativeImages.map((image, index) => (
				<img
					key={index}
					src={image.src}
					alt={image.alt}
					className={`absolute w-full md:w-[386px] h-[495px] rounded-[32px] z-${image.zIndex} rotate-[${image.rotation}]`}
				/>
			))}
		</section>
	);
};
