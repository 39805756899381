import { useQuery } from '@tanstack/react-query';

import { IPagination } from '@/interface/pagination.interface';
import { getMostLikedRecipes } from '@/service/api/recipe';

export const useGetMostLikedRecipes = (options: IPagination) => {
	const { take, skip } = options;
	const {
		data: mostLikedRecipes,
		isLoading: isLoadingMostLikedRecipes,
		isError: isErrorMostLikedRecipes,
		error: errorMostLikedRecipes,
		refetch: refetchMostLikedRecipes,
	} = useQuery({
		queryKey: ['most-liked-recipes', take, skip],
		queryFn: () => getMostLikedRecipes(options),
		retry: false,
	});

	return {
		mostLikedRecipes,
		refetchMostLikedRecipes,
		totalEntities: mostLikedRecipes?.total as number,
		isLoadingMostLikedRecipes,
		isErrorMostLikedRecipes,
		errorMostLikedRecipes,
	};
};
