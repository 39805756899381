import { ErrorBoundary } from 'react-error-boundary';
import { Outlet } from 'react-router-dom';

import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';

interface IErrorBoundaryNoAuthWrapperProps {
	isLandingPage?: boolean;
}

const ErrorBoundaryNoAuthWrapper = ({
	isLandingPage,
}: IErrorBoundaryNoAuthWrapperProps) => (
	<ErrorBoundary
		fallbackRender={(props) => (
			<ErrorBoundaryFallback {...props} isLandingPage={isLandingPage} />
		)}
	>
		<Outlet />
	</ErrorBoundary>
);

export default ErrorBoundaryNoAuthWrapper;
