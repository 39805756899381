import { INutritionalInformation } from '@/interface/recipe.interfaces';

export const NutricionalInformationComponent = ({
	nutrients,
}: {
	nutrients: INutritionalInformation;
}) => {
	return (
		<div className="w-full">
			<h4 className="text-[26px] leading-[28.6px] tracking-[0.02em] font-semibold mb-6">
				Nutritional Information:
			</h4>
			<div className="grid grid-cols-2 gap-4 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-3 2xl:grid-cols-5">
				<div className="flex flex-col items-center px-4 py-6 rounded-3xl border-2 border-brandRed ">
					<span className="mr-1 uppercase text-[#B5B5B5] text-[10px] ">
						Calories
					</span>
					<span className="text-[18px] leading-[19.8px] font-semibold text-brandRed">
						{nutrients.calories}
					</span>
				</div>
				<div className="flex flex-col items-center px-4 py-6 rounded-3xl border-2 border-brandRed ">
					<span className="mr-1 uppercase text-[#B5B5B5] text-[10px] ">
						Fat
					</span>
					<span className="text-[18px] leading-[19.8px] font-semibold text-brandRed">
						{nutrients.fat}
					</span>
				</div>
				<div className="flex flex-col items-center px-4 py-6 rounded-3xl border-2 border-brandRed ">
					<span className="mr-1 uppercase text-[#B5B5B5] text-[10px] ">
						Protein
					</span>
					<span className="text-[18px] leading-[19.8px] font-semibold text-brandRed">
						{nutrients.protein}
					</span>
				</div>
				<div className="flex flex-col items-center px-4 py-6 rounded-3xl border-2 border-brandRed ">
					<span className="mr-1 uppercase text-[#B5B5B5] text-[10px] ">
						Carbs
					</span>
					<span className="text-[18px] leading-[19.8px] font-semibold text-brandRed">
						{nutrients.carbohydrates}
					</span>
				</div>
			</div>
		</div>
	);
};
