import { useState } from 'react';

import { useUpdatePreferences } from './useUpdateUserPreferences';

import { User } from '@/context/interfaces';
import {
	FavoriteChefs,
	FavoriteIngredients,
	ICookingLevel,
	RecipePreferences,
	UserPreferences,
} from '@/interface/user.interfaces';

export const useUserPreferences = (user: User) => {
	const { updatePreferencesMutation } = useUpdatePreferences();

	const [preferences, setPreferences] = useState<UserPreferences>({
		favoriteChefs: user.favoriteChefs || [],
		favoriteIngredients: user.favoriteIngredients || [],
		cookingLevel: user.cookingLevel,
		recipePreferences: user.recipePreferences,
	});

	const handleRecipePreferences = (recipePreferences: RecipePreferences) => {
		const updatedChefs = preferences.recipePreferences?.includes(
			recipePreferences,
		)
			? preferences.recipePreferences.filter((rp) => rp !== recipePreferences)
			: [...(preferences.recipePreferences || []), recipePreferences];
		const newPreferences = { ...preferences, recipePreferences: updatedChefs };
		setPreferences(newPreferences);
		updatePreferencesMutation.mutate({ userId: user.id, newPreferences });
	};

	const handleChefChange = (chef: FavoriteChefs) => {
		const updatedChefs = preferences.favoriteChefs?.includes(chef)
			? preferences.favoriteChefs.filter((c) => c !== chef)
			: [...(preferences.favoriteChefs || []), chef];
		const newPreferences = { ...preferences, favoriteChefs: updatedChefs };
		setPreferences(newPreferences);
		updatePreferencesMutation.mutate({ userId: user.id, newPreferences });
	};

	const handleIngredientChange = (ingredient: FavoriteIngredients) => {
		const updatedIngredients = preferences.favoriteIngredients?.includes(
			ingredient,
		)
			? preferences.favoriteIngredients.filter((i) => i !== ingredient)
			: [...(preferences.favoriteIngredients || []), ingredient];
		const newPreferences = {
			...preferences,
			favoriteIngredients: updatedIngredients,
		};
		setPreferences(newPreferences);
		updatePreferencesMutation.mutate({ userId: user.id, newPreferences });
	};

	const handleCookingLevelChange = (level: ICookingLevel) => {
		const newPreferences = { ...preferences, cookingLevel: level };
		setPreferences(newPreferences);
		updatePreferencesMutation.mutate({ userId: user.id, newPreferences });
	};

	return {
		preferences,
		handleChefChange,
		handleIngredientChange,
		handleCookingLevelChange,
		handleRecipePreferences,
		isPendingPreferencesChange: updatePreferencesMutation.isPending,
	};
};
