import { EventContentArg, EventInput } from '@fullcalendar/core/index.js';

import { CreateMealPlanForm } from './CreateMealPlanForm';
import { DeleteMealPlanForm } from './DeleteMealPlanForm';
import { UpdateMealPlanForm } from './UpdateMealPlanForm';

interface RenderEventContentProps {
	eventContent: EventContentArg;
	events: EventInput[];
	setEvents: React.Dispatch<React.SetStateAction<EventInput[]>>;
}

export const RenderEventContent = ({
	eventContent,
	setEvents,
}: RenderEventContentProps) => {
	const { id, title, extendedProps } = eventContent.event;
	const recipe = extendedProps.recipe;

	return (
		<div>
			{title ? (
				<div className="flex p-3 w-full ">
					<div className="w-[92%] h-[200px] flex flex-col justify-between p-4 my-2 bg-white rounded-[45px] shadow-lg hover:ring-2 hover:ring-brandRed ">
						<div className="grid grid-cols-8 items-center px-2">
							<span className="font-semibold text-brandRed col-span-5 ">
								{title}
							</span>
							<div className="flex gap-2 col-span-3 justify-end ">
								<UpdateMealPlanForm
									selectedEvent={eventContent}
									setEvents={setEvents}
								/>

								<DeleteMealPlanForm
									key={Number(id)}
									id={Number(extendedProps.mealPlanId)}
									selectedEvent={eventContent}
									setEvents={setEvents}
								/>
							</div>
						</div>
						<div className="mb-2">
							<div
								className="text-2xl min-[400px]:text-3xl sm:text-2xl xl:text-3xl font-semibold pt-5 pb-3 truncate whitespace-nowrap overflow-hidden"
								style={{ maxWidth: '100%' }}
							>
								{recipe.title}
							</div>
							{recipe.servings != null &&
								recipe.servings != 0 &&
								Number.isFinite(recipe.servings) && (
									<div>Serving size {recipe.servings}</div>
								)}
						</div>
					</div>
				</div>
			) : (
				<div className="p-3 justify-center">
					<CreateMealPlanForm
						selectedEvent={eventContent}
						setEvents={setEvents}
					/>
				</div>
			)}
		</div>
	);
};
