import { useEffect, useState } from 'react';
import { useInstantSearch, useSearchBox } from 'react-instantsearch';

import { AlgoliaStatusLoader } from './AlgoliaStatusLoader';

import crossIcon from '@/assets/svg/cross.svg';
import searchIconGray from '@/assets/svg/search-icon-gray.svg';

export const CustomAlgoliaSearchBox = ({
	searchWord,
}: {
	searchWord?: string;
}) => {
	const { query, refine } = useSearchBox();
	const { status } = useInstantSearch();
	const [inputValue, setInputValue] = useState(query);
	const [debouncedInputValue, setDebouncedInputValue] = useState(query);

	const isSearchStalled = status === 'stalled';

	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedInputValue(inputValue);
		}, 600);

		return () => clearTimeout(handler);
	}, [inputValue]);

	useEffect(() => {
		refine(debouncedInputValue);
	}, [debouncedInputValue, refine]);

	useEffect(() => {
		if (searchWord) {
			setInputValue(searchWord);
			refine(searchWord);
		}
	}, [searchWord, refine]);

	return (
		<form
			noValidate
			role="search"
			action=""
			onSubmit={(event) => {
				event.preventDefault();
				event.stopPropagation();

				refine(inputValue);
			}}
			onReset={(event) => {
				event.preventDefault();
				event.stopPropagation();

				setInputValue('');
				refine('');
			}}
			className="w-full flex justify-center items-center gap-x-4"
		>
			<div className="w-full sm:w-96 gap-x-2 items-center relative focus-within:text-gray-400">
				<span className="absolute inset-y-0 right-0 flex items-center pr-2">
					{inputValue.length === 0 || isSearchStalled ? (
						<span className="p-1 focus:outline-none focus:shadow-outline cursor-pointer">
							<img src={searchIconGray} alt="search-icon" />
						</span>
					) : (
						<button type="reset" className="text-sm mr-2">
							<img src={crossIcon} alt="cross" />
						</button>
					)}
				</span>
				<input
					data-cy="navbar-search-input"
					onChange={(e) => setInputValue(e.target.value)}
					autoComplete="off"
					autoCorrect="off"
					autoCapitalize="off"
					type="text"
					value={inputValue}
					className=" w-full text-sm focus:ring-1 focus:ring-brandOrange focus:bg-white py-[12px] pl-6 pr-4 focus:outline-none focus:text-gray-700 rounded-2xl bg-slate-100 text-slate-300 placeholder-slate-500 border-none"
					placeholder="Search recipes..."
				/>
			</div>
			<AlgoliaStatusLoader />
		</form>
	);
};
