import { useEffect } from 'react';
import { Hits, useInstantSearch, useSearchBox } from 'react-instantsearch';

import { AlgoliaHit } from '@/components/algolia/AlgoliaHit';
import { upperCaseFirstLetter } from '@/helper/upperCaseFirstLetter';

export const AlgoliaResults = () => {
	const { results, indexUiState } = useInstantSearch();
	const { refine } = useSearchBox();
	const originalQuery = indexUiState.query;

	useEffect(() => {
		if (results && results.nbHits === 0 && originalQuery) {
			const timeoutId = setTimeout(() => {
				refine('');
			}, 2000);

			return () => clearTimeout(timeoutId);
		}
	}, [results, originalQuery, refine]);

	return (
		<div className="w-full">
			<div>
				{originalQuery ? (
					<h4
						data-cy="search-results-title"
						className="font-semibold text-3xl md:text-4xl 2xl:text-5xl mb-6"
					>
						{results && results.nbHits === 0 ? (
							<>
								We couldn’t find any results for{' '}
								<span className="text-brandRed">
									{upperCaseFirstLetter(originalQuery)}
								</span>
								, but here are some suggestions:
							</>
						) : (
							<>
								Search Results for{' '}
								<span className="text-brandRed">
									{upperCaseFirstLetter(originalQuery)}
								</span>
							</>
						)}
					</h4>
				) : null}

				<Hits
					hitComponent={AlgoliaHit}
					classNames={{
						root: 'w-full mt-6',
						list: 'grid justify-center gap-y-10 gap-x-2 min-[375px]:grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 2xl:grid-cols-[repeat(6,235px)]',
					}}
				/>
			</div>
		</div>
	);
};
