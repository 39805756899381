import { useRef, useState } from 'react';

import { RenderVideoOrImage } from '../RenderVideoOrImage';

import { MasonryCardInfo } from '@/components/common/recipe/masonry-recipe/MasonryCardInfo';
import { CardInfoProps } from '@/components/common/recipe/recipe-card/CardInfo';
import useWindowWidth from '@/hooks/useWindowWidth';
import { logVideoPlay } from '@/service/analytics/analytics';

interface RecipeCardProps
	extends Omit<CardInfoProps, 'setIndexToFavouriteAndSaved'> {
	height: 'normal' | 'higher' | 'high';
	isVideo?: boolean;
	mediaUrl?: string;
	thumbnailUrl?: string;
}

export const RecipeDinamicCard = ({
	recipe,
	height,
	mediaUrl,
	isVideo,
	thumbnailUrl,
}: RecipeCardProps) => {
	const [play, setPlay] = useState(false);
	const [isVideoFetched, setIsVideoFetched] = useState(false);
	const hasThumbnail = isVideo && thumbnailUrl;
	const [recipeOverEffect, setRecipeOverEffect] = useState(false);
	const hoverTimeoutRef = useRef<number | null>(null);
	const windowWidth = useWindowWidth();

	const handlePlayToggle = () => {
		setIsVideoFetched(true);
		setPlay(!play);
	};

	const handlePlayClick = () => {
		if (isVideo) {
			const chefFullName = `${recipe.chef?.user?.name || 'Unknown'} ${
				recipe.chef?.user?.last_name || 'Unknown'
			}`;
			logVideoPlay(recipe.uuid, recipe.title, chefFullName);
		}
	};

	const handleMouseEnter = () => {
		if (hoverTimeoutRef.current === null) {
			hoverTimeoutRef.current = window.setTimeout(() => {
				windowWidth > 1024 && setRecipeOverEffect(true);
				isVideo && handlePlayToggle();
				isVideo && handlePlayClick();
			}, 1000);
		}
	};

	const handleMouseLeave = () => {
		if (hoverTimeoutRef.current !== null) {
			setRecipeOverEffect(false);
			clearTimeout(hoverTimeoutRef.current);
			hoverTimeoutRef.current = null;
			play && handlePlayToggle();
		}
	};

	let imageStyle = '';
	switch (height) {
		case 'normal':
			imageStyle = 'h-[240px] md:h-[300px]';
			break;
		case 'high':
			imageStyle = 'h-[300px] md:h-[360px]';
			break;
		case 'higher':
			imageStyle = 'h-[340px] md:h-[420px]';
			break;
		default:
			imageStyle = 'h-[240px] md:h-[300px]';
			break;
	}

	return (
		<div
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			data-cy="recipe-dinamic-card"
			className={`flex flex-col max-w-[235px] w-[250px] min-[375px]:w-full 2xl:w-[235px] ${
				recipeOverEffect &&
				'index bg-white shadow-card rounded-3xl shadow-sm shadow-gray-600'
			}`}
			style={
				recipeOverEffect
					? {
							animationName: 'card',
							animationDuration: '0.6s',
							animationFillMode: 'forwards',
							animationTimingFunction: 'cubic-bezier(0.25, 0.1, 0.25, 1.0)',
					  }
					: undefined
			}
		>
			{RenderVideoOrImage({
				isVideoFetched,
				mediaUrl,
				hasThumbnail,
				play,
				isVideo,
				imageStyle,
				handlePlayToggle: () => {
					handlePlayToggle();
					handlePlayClick();
				},
				recipeUuid: recipe.uuid,
				recipeTitle: recipe.title,
				chefFullName: `${recipe.chef?.user?.name || 'Unknown'} ${
					recipe.chef?.user?.last_name || 'Unknown'
				}`,
				containerStyle: 'w-full relative',
				onPlayClick: handlePlayClick,
			})}

			<MasonryCardInfo recipe={recipe} />
		</div>
	);
};
