import { useState } from 'react';

import settingsBlackIcon from '@/assets/svg/general-settings-black-icon.svg';
import preferencesBlackIcon from '@/assets/svg/preferences-black-icon.svg';
import preferencesWhiteIcon from '@/assets/svg/preferences-white-icon.svg';
import settingsWhiteIcon from '@/assets/svg/settings-withe.svg';

interface GeneralSettingsButtonsProps {
	selectConfigView: string;
	setSelectConfigView: React.Dispatch<React.SetStateAction<string>>;
}

export default function GeneralSettingsButtons({
	selectConfigView,
	setSelectConfigView,
}: GeneralSettingsButtonsProps) {
	const [hoveredButton, setHoveredButton] = useState('');

	const handleButtonClick = (view: string) => {
		setSelectConfigView(view);
	};

	const handleMouseEnter = (buttonType: string) => {
		setHoveredButton(buttonType);
	};

	const handleMouseLeave = () => {
		setHoveredButton('');
	};

	return (
		<div className="flex flex-wrap gap-x-4 gap-y-1">
			<button
				data-cy={`settings-general-settings-button`}
				className={`grow flex items-center justify-center h-12 gap-x-2 py-4 px-4 rounded-3xl ${
					selectConfigView === 'GeneralSettings'
						? 'bg-brandOrange text-white hover:cursor-pointer'
						: 'bg-slate-50 text-black hover:bg-brandOrange hover:text-white transition-colors hover:ring-2 hover:ring-white hover:cursor-pointer'
				}`}
				onClick={() => handleButtonClick('GeneralSettings')}
				onMouseEnter={() => handleMouseEnter('GeneralSettings')}
				onMouseLeave={handleMouseLeave}
			>
				<img
					src={
						selectConfigView === 'GeneralSettings' ||
						hoveredButton === 'GeneralSettings'
							? settingsWhiteIcon
							: settingsBlackIcon
					}
					alt={`settings-${
						selectConfigView === 'GeneralSettings' ? 'white' : 'black'
					}-icon}`}
				/>
				<span>General Settings</span>
			</button>
			<button
				data-cy={`settings-preferences-button`}
				className={`grow flex items-center justify-center h-12 gap-x-2 py-4 px-4 rounded-3xl ${
					selectConfigView === 'Preferences'
						? 'bg-brandOrange text-white hover:cursor-pointer'
						: 'bg-slate-50 text-black hover:bg-brandOrange hover:text-white transition-colors hover:ring-2 hover:ring-white hover:cursor-pointer'
				}`}
				onClick={() => handleButtonClick('Preferences')}
				onMouseEnter={() => handleMouseEnter('Preferences')}
				onMouseLeave={handleMouseLeave}
			>
				<img
					src={
						selectConfigView === 'Preferences' ||
						hoveredButton === 'Preferences'
							? preferencesWhiteIcon
							: preferencesBlackIcon
					}
					alt={`settings-${
						selectConfigView === 'Preferences' ? 'white' : 'black'
					}-icon}`}
				/>
				<span>Preferences</span>
			</button>
		</div>
	);
}
