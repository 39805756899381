import { useEffect, useState } from 'react';

import { Title } from '../common/Title/Title';
import { RecipesCard } from '../common/recipe/recipe-card/RecipesCard';

import { Loader } from '@/components/common/Loader';
import { NavigationButtons } from '@/components/common/buttons/navigation-button/NavigationButtons';
import { usePagination } from '@/hooks/usePagination';
import { useGetUserSavedRecipes } from '@/hooks/user/useGetUserSavedRecipes';

export const MyListSavedRecipe = () => {
	const [skip, setSkip] = useState(0);
	const take = 5;

	const {
		userSavedRecipes,
		isLoadingUserSavedRecipes,
		totalUserSavedEntities,
		refetchUserSavedRecipes,
		errorUserSavedRecipes,
	} = useGetUserSavedRecipes({ take, skip });

	const { totalPages, currentPage, onHandleNext, onHandlePrevious } =
		usePagination({
			take,
			skip,
			totalEntities: totalUserSavedEntities,
			setSkip,
		});

	useEffect(() => {
		refetchUserSavedRecipes();
	}, [skip]);

	if (isLoadingUserSavedRecipes)
		return (
			<div className="flex justify-center items-center mt-6">
				<Loader size={4} />
			</div>
		);

	if (errorUserSavedRecipes) return <></>;

	return (
		<div
			data-cy="my-saved-list-section"
			className="w-full pt-10 xl:pt-0 space-y-5"
		>
			<div className="flex items-center gap-x-4 px-8">
				<Title textTitle="My" colorText="brandRed" titleWithColor="List" />
			</div>

			{userSavedRecipes && userSavedRecipes.data.length > 0 ? (
				<div className="space-y-10">
					<div className="ml-5 grid justify-center gap-y-10 gap-x-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
						{userSavedRecipes?.data.map((recipe) => (
							<div
								data-cy="user-cards-saved-recipes"
								key={recipe.id}
								className="w-full max-w-[235px]"
							>
								<RecipesCard recipe={recipe} />
							</div>
						))}
					</div>

					<NavigationButtons
						currentPage={currentPage}
						totalPages={totalPages}
						handleNextClick={onHandleNext}
						handlePrevClick={onHandlePrevious}
					/>
				</div>
			) : (
				<div className="flex items-center justify-center h-full">
					<h3 className="text-xl md:text-2xl font-semibold tracking-[-0.04em] text-center p-8 w-[350px]">
						You do not have any saved recipes yet. Please save a recipe first so
						you can add it to your meal plans.
					</h3>
				</div>
			)}
		</div>
	);
};
