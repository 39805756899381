import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Loader } from '../common/Loader';
import { NavbarLinks } from './components/NavbarLinks';
import { SearchInput } from './components/SearchInput';

import arrowUpRight from '@/assets/svg/arrow-up-right.svg';
import greenLogo from '@/assets/svg/navbar-logo-green.svg';
import { DropDown } from '@/components/navbar/components/DropDown';
import { useAuth } from '@/context/useAuth';
import { notifyInfo } from '@/helper/notifications';

const Navbar = () => {
	const { user, logout, loading } = useAuth();
	const [scrolled, setScrolled] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const isSearchPage = location.pathname.startsWith('/search');

	const onLogout = () => {
		logout();
		navigate('/');
		notifyInfo('Logged out');
	};

	useEffect(() => {
		const handleScroll = () => {
			if (window.scrollY > 50) {
				setScrolled(true);
			} else {
				setScrolled(false);
			}
		};

		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<nav
			data-cy="navbar"
			className={`w-full z-10 bg-transparent  ${
				scrolled ? ' fixed bg-white' : location.pathname == '/' && 'absolute'
			}
			`}
		>
			<div className="flex items-center justify-between px-1 min-[400px]:px-4 sm:px-10 py-2 ">
				<div className="flex items-center gap-4">
					<Link to="/" data-cy="navbar-logo">
						<img src={greenLogo} alt="Flowbite Logo" className="h-6" />
					</Link>

					<NavbarLinks scrolled={scrolled} path={location.pathname} />
				</div>

				<div className="gap-4" id="navbar-default">
					<ul className="font-medium flex md:p-0 ">
						{loading ? (
							<div className="flex justify-center items-center mr-8 ">
								<Loader size={2} />
							</div>
						) : user ? (
							<div className="flex items-center gap-4">
								{!isSearchPage && <SearchInput />}
								<DropDown onLogout={onLogout} user={user} />
							</div>
						) : (
							<li>
								<div className="py-[12px] px-6 text-sm rounded-3xl bg-slate-100 border-none hover:bg-slate-200 hover:cursor-pointer">
									<Link
										to="/login"
										className="font-semibold"
										data-cy="navbar-login"
									>
										Login
									</Link>
								</div>
								<div className="relative flex items-center py-[12px] pr-8 pl-6 text-sm text-white bg-brandOrange rounded-3xl transition-all hover:text-brandOrange hover:bg-white hover:ring-2 hover:ring-[#E1B864] hover:cursor-pointer">
									<Link
										to="/signup"
										className="font-semibold"
										data-cy="navbar-signup"
									>
										Sign up
									</Link>
									<span className="p-[2px] bg-brandOrange rounded-full border-2 border-white absolute -right-3 ">
										<img src={arrowUpRight} alt="arrow-up-right-icon" />
									</span>
								</div>
							</li>
						)}
					</ul>
				</div>
			</div>
		</nav>
	);
};

export default Navbar;
