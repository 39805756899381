import { Link } from 'react-router-dom';

export const NotFound = () => {
	return (
		<div
			className="flex mt-20 items-center flex-col w-full h-screen gap-6"
			data-cy="not-found-page-container"
		>
			<h1 className="text-black text-4xl sm:text-5xl  font-sans font-bold tracking-tighter">
				OOPS! Page not found
			</h1>
			<span className="text-slate-400 text-lg md:text-xl">
				The page you are looking for does not exist
			</span>
			<Link
				to="/"
				className="rounded-full bg-brandRed px-4 py-2 font-sans font-medium"
			>
				Go back home
			</Link>
		</div>
	);
};
